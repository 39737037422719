import { call, delay, put as putInternal, race, select, take } from "redux-saga/effects";
import {
  BadRequestError,
  Error400Model,
  Error402Model,
  ErrorNoContent,
  NotFoundRequestError,
  PaymentRequiredError,
  UnauthorizedError,
} from "./ErrorModel";
import { authSlice } from "./auth/AuthSlice";
import { deleteCookie, getCookieString } from "./auth/AuthRepo";
import { AppSettings } from "./AppSettings";
import { LanguageCodeEnum } from "./user/UserModel";
import { selectLanguage } from "./user/UserSlice";

declare const appSettings: AppSettings;

const dominoApiName = appSettings.apiBaseUrl;
const identityApi = appSettings.apiIdentityProvider;
const shoppingCartApiName = appSettings.apiShoppingCartUrl;
const billingApiName = appSettings.billingPortalApi;

let isExpired = true;

function* getToken(apiName: string) {
  yield putInternal(authSlice.actions.getToken(apiName));

  while (true) {
    const { action, cancel } = yield race({
      action: take(authSlice.actions.tokenReceived),
      cancel: take(authSlice.actions.tokenRejected),
    });

    if (action) {
      if (action.payload.apiName === apiName) {
        return action.payload.token;
      }
    } else if (cancel) {
      throw new UnauthorizedError("Authentication rejected");
    }
  }
}

export function* httpGet(resourceUrl: string): unknown {
  const result = yield fetchBase("GET", dominoApiName, resourceUrl);
  return result;
}

export function* httpPost<TData>(resourceUrl: string, data: TData): unknown {
  const result = yield fetchBase("POST", dominoApiName, resourceUrl, data, undefined);
  return result;
}

export function* httpPut<TData>(resourceUrl: string, data: TData): unknown {
  const result = yield fetchBase("PUT", dominoApiName, resourceUrl, data);
  return result;
}

export function* httpDelete(resourceUrl: string): unknown {
  const result = yield fetchBase("DELETE", dominoApiName, resourceUrl);
  return result;
}

export function* httpPostForm(resourceUrl: string, form: FormData): unknown {
  const result = yield fetchFormBase("POST", dominoApiName, resourceUrl, form);
  return result;
}

export function* httpPostFormShoppingCart(resourceUrl: string, form: FormData): unknown {
  const result = yield fetchFormBase("POST", shoppingCartApiName, resourceUrl, form);
  return result;
}

export function* httpPostShoppingCart<TData>(resourceUrl: string, data: TData): unknown {
  const result = yield fetchBase("POST", shoppingCartApiName, resourceUrl, data, undefined);
  return result;
}

export function* httpGetShoppingCart(resourceUrl: string): unknown {
  const result = yield fetchBase("GET", shoppingCartApiName, resourceUrl);
  return result;
}

export function* httpGetBillingPortalApi(resourceUrl: string): unknown {
  const result = yield fetchBase("GET", billingApiName, resourceUrl);
  return result;
}

export function* httpPostBillingPortalApi<TData>(resourceUrl: string, data: TData): unknown {
  const result = yield fetchBase("POST", billingApiName, resourceUrl, data, undefined);
  return result;
}

export function* httpGetIdentity(resourceUrl: string): unknown {
  const result = yield fetchBase("GET", identityApi, resourceUrl);
  return result;
}

export function* httpPostIdentity<TData>(resourceUrl: string, data: TData): unknown {
  const result = yield fetchBase("POST", identityApi, resourceUrl, data, undefined);
  return result;
}

export const getOrganisationIdFromStorage = () => {
  const username = localStorage.getItem("username") || sessionStorage.getItem("username") || "";
  let organizationIdSession: string | null = null;
  let organizationIdLocal: string | null = null;
  const cookiesOrgId = getCookieString("organizationId");

  organizationIdSession = JSON.parse(sessionStorage.getItem("usersOrganizationId") || "{}")[username];
  organizationIdLocal = JSON.parse(localStorage.getItem("usersOrganizationId") || "{}")[username];
  const token = getCookieString("token");
  if (token) {
    const availableOrganizations = JSON.parse(atob(token.split(".")[1])).AvailableOrganizations;

    if (organizationIdSession && availableOrganizations.includes(organizationIdSession)) {
      return organizationIdSession;
    } else if (organizationIdLocal && availableOrganizations.includes(organizationIdLocal)) {
      return organizationIdLocal;
    } else if (cookiesOrgId && availableOrganizations.includes(cookiesOrgId)) {
      return cookiesOrgId;
    } else {
      const orgId = JSON.parse(atob(token.split(".")[1])).Organization;
      return orgId;
    }
  }
  return "";
};

function* fetchBase(method: string, apiName: string, resourceUrl: string, data: unknown = null, retry = false): unknown {
  const token = yield call(getToken, apiName);
  const organizationId = getOrganisationIdFromStorage();
  const lng: LanguageCodeEnum = yield select(selectLanguage);

  const result = yield fetch(`${apiName}${resourceUrl}`, {
    method: method,
    headers: {
      Authorization: "Bearer " + token ?? "",
      Accept: "application/vnd.domino.v1+json",
      "Content-Type": `application/vnd.domino.v1+json`,
      Pragma: "no-cache",
      "Cache-Control": "no-cache",
      "x-domino-lang": lng,
      "x-domino-organization": organizationId,
    },
    body: data ? JSON.stringify(data) : undefined,
  });

  return yield handleResponse(result, method, apiName, resourceUrl, retry, data);
}

function* handleResponse(
  response: Response,
  method: string,
  apiName: string,
  resourceUrl: string,
  retry?: boolean,
  data: unknown = null,
): unknown {
  if (response?.status === 401 && !retry) {
    isExpired = !isExpired;
    deleteCookie("token");
    response = yield fetchBase(method, apiName, resourceUrl, data, true);
  }

  if (response?.status === 200) {
    isExpired = false;
    if (retry) {
      return response;
    }
    if (resourceUrl.startsWith("/file")) {
      const fileBlob = yield response.blob();
      try {
        return fileBlob;
      } catch {
        return fileBlob;
      }
    }
    if (resourceUrl.startsWith("/file/")) {
      const fileBlob = yield response.blob();
      try {
        return fileBlob;
      } catch {
        return fileBlob;
      }
    }
    const textResponse = yield response.text();
    try {
      return JSON.parse(textResponse);
    } catch {
      return textResponse;
    }
  }

  if (response?.status === 400) {
    const data: Error400Model = yield response.json();
    throw new BadRequestError(data?.uiMessage ?? data.message, data.validationSource?.flowId, data.validationSource?.nodeId);
  }

  if (response?.status === 402) {
    const data: Error402Model = yield response.json();
    throw new PaymentRequiredError(
      data?.uiMessage ?? data.message,
      data.validationSource?.maxCount,
      data.validationSource?.isUnlimited,
    );
  }

  if (response?.status === 204) {
    yield delay(1000);
    throw new ErrorNoContent("No Data");
  }

  if (response?.status === 404) {
    throw new NotFoundRequestError();
  }
}

function* fetchFormBase(method: string, apiName: string, resourceUrl: string, form: FormData, retry = false): unknown {
  const token = yield call(getToken, apiName);
  const organizationId = getOrganisationIdFromStorage();

  const lng: LanguageCodeEnum = yield select(selectLanguage);

  const result = yield fetch(`${apiName}${resourceUrl}`, {
    method: method,
    headers: {
      Authorization: "Bearer " + token,
      Accept: "application/vnd.domino.v1+json",
      "x-domino-lang": lng,
      "x-domino-organization": organizationId,
    },
    body: form,
  });

  return yield handleResponseForm(result, method, apiName, resourceUrl, form, retry);
}

function* handleResponseForm(
  response: Response,
  method: string,
  apiName: string,
  resourceUrl: string,
  form: FormData,
  retry?: boolean,
): unknown {
  if (response?.status === 401 && !retry) {
    isExpired = !isExpired;
    deleteCookie("token");
    response = yield fetchFormBase(method, apiName, resourceUrl, form, true);
  }

  if (response?.status === 200) {
    isExpired = false;
    if (retry) {
      return response;
    }
    if (resourceUrl.startsWith("/file")) {
      const fileBlob = yield response.blob();
      try {
        return fileBlob;
      } catch {
        return fileBlob;
      }
    }
    const textResponse = yield response.text();
    try {
      return JSON.parse(textResponse);
    } catch {
      return textResponse;
    }
  }

  if (response?.status === 400) {
    const data: Error400Model = yield response.json();
    throw new BadRequestError(data?.uiMessage ?? data.message, data.validationSource?.flowId, data.validationSource?.nodeId);
  }

  if (response?.status === 204) {
    yield delay(1000);
    throw new ErrorNoContent("No Data");
  }

  if (response?.status === 404) {
    throw new NotFoundRequestError();
  }
}
