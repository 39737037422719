import { Button, Flex, Icon, Text } from "@chakra-ui/react";
import s from "./RegistrationSuccessful.module.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CheckFillCircle } from "../../assets/icons/checkFillCircle.svg";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setTokenRegistration } from "../signUp/SignUpSlice";
import { Loading } from "../../common/loading/LoadingStateContainer";
import { useGetLoadingState } from "../../common/loading/hooks/useGetLoadingState";

export const RegistrationSuccessful = () => {
  const { t } = useTranslation("translation", { keyPrefix: "registrationSuccessful" });
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const location = useLocation();

  const isLoading = useGetLoadingState("registrationSuccessful");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    if (token) dispatch(setTokenRegistration(token));
  }, [location]);

  return (
    <>
      {isLoading ? (
        <Flex className={s.loading}>
          <Loading scope="registrationSuccessful" />
        </Flex>
      ) : (
        <Flex className={s.wrapper}>
          <Flex className={s.card}>
            <Flex className={s.cardHeader}>
              <Icon as={CheckFillCircle} boxSize="80px" />
              <Text className={s.cardTitle} variant="h1">
                {t("Registration successful")}
              </Text>
            </Flex>
            <Flex className={s.cardFooter}>
              <Button lineHeight="20px" onClick={() => navigate("/addbot")} variant="dominoViolet">
                {t("Create New Bot")}
              </Button>
              <Text textAlign="center" variant="medium">
                {t("One more thing, for the bot to be able to interact with users")}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      )}
    </>
  );
};
