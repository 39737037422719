import { Edge, MarkerType, Node } from "reactflow";
import {
  AssignFlowActionData,
  CalendarFlowActionData,
  ConditionFlowActionData,
  CurlFlowActionData,
  DelayFlowActionData,
  EdgeData,
  FileData,
  FlowActionData,
  FlowData,
  InlineKeyboardMarkupData,
  NodeButtonData,
  NodeData,
  PaymentFlowActionData,
  QuestionFlowActionData,
  ReplyKeyboardMarkupData,
  ReplyMarkupData,
  SchedulerTriggerData,
  SendAudioFlowActionData,
  SendDocumentFlowActionData,
  SendPhotoFlowActionData,
  SendTextFlowActionData,
  SendVideoFlowActionData,
  ShoppingCartFlowActionData,
  StartFlowActionData,
  TriggerData,
} from "./FlowBuilderData";
import {
  AssignFlowActionModel,
  ButtonsContainerFlowAction,
  CalendarFlowActionModel,
  ConditionFlowActionModel,
  CurlFlowActionModel,
  CustomVariablesModel,
  DaysUnits,
  DelayFlowActionModel,
  FileInfoModel,
  FlowActionModel,
  FlowActionType,
  FlowModel,
  HandleNegativeTypes,
  HandlePositiveTypes,
  NodeModel,
  NodeType,
  PaymentFlowActionModel,
  PutItemIntoArrayModel,
  QuestionFlowActionModel,
  ScheduleTriggerTypeDiscriminator,
  SendAudioFlowActionModel,
  SendDocumentFlowActionModel,
  SendPhotoFlowActionModel,
  SendTextFlowActionModel,
  SendVideoFlowActionModel,
  SetVariableModel,
  ShoppingCartFlowActionModel,
  StartFlowActionModel,
  SystemActionFlowActionModel,
  TriggerTypeDiscriminator,
  WorkingTimeModel,
} from "./FlowBuilderModel";
import { ButtonType, ButtonTypeDiscriminator, FlowMarkupTypeDiscriminator } from "../../common/AppEnums";
import {
  ButtonModel,
  InlineKeyboardMarkupModel,
  ReplyButtonData,
  ReplyKeyboardButtonModel,
  ReplyKeyboardMarkupModel,
  ReplyMarkupModel,
} from "../../common/AppButtonsModel";
import {
  convertDateIsotoLocal,
  convertDateToISO,
  formatDateTimeToString,
  formatDateTimeToUTC,
} from "../../common/utils/formatDate";

export const chooseDay = (day: string) => {
  switch (day) {
    case DaysUnits.Mon:
      return 0;
    case DaysUnits.Tue:
      return 1;
    case DaysUnits.Wed:
      return 2;
    case DaysUnits.Thu:
      return 3;
    case DaysUnits.Fri:
      return 4;
    case DaysUnits.Sat:
      return 5;
    case DaysUnits.Sun:
      return 6;

    default:
      return 0;
  }
};

export const mapTriggersToData = (
  flow: FlowModel,
  cusVars: CustomVariablesModel[],
  convertDate: { (dateValue: string): string; (date: string): string; (arg0: string): any },
) => {
  const newTriggers = flow.triggers.map(trigger => {
    if (trigger && trigger.conditions) {
      const newConditions = trigger.conditions.map(condition => {
        const customVar = cusVars?.find(
          variable =>
            !(condition.condition === "HasValue" || condition.condition === "HasNoValue") &&
            variable.key === condition.conditionCustomVariableName &&
            variable.scope === "Contact" &&
            variable.type === "DateTime",
        );
        if (customVar) {
          return {
            ...condition,
            value: convertDate(condition.value ?? ""),
          };
        }
        return condition;
      });
      return {
        ...trigger,
        conditions: newConditions,
      };
    }
    return trigger;
  });
  return newTriggers;
};

export const mapFlowToData = (
  flow: FlowModel,
  cusVars: CustomVariablesModel[],
  fileUrlCusVars?: CustomVariablesModel[],
): FlowData => {
  const edges: EdgeData[] = flow.edges.map(el => {
    return {
      id: el.id,
      sourceId: el.source,
      sourceHandleId: el.sourceHandle || undefined,
      targetId: el.target,
      isDirect: el.sourceHandle === "common-source",
    };
  });

  const nodes: NodeData[] = flow.nodes.map(el => {
    let nodeData: FlowActionData | undefined = undefined;
    const flowActionModel = el.data as FlowActionModel;

    if (flowActionModel && flowActionModel.typeDiscriminator) {
      nodeData = mapFlowActionToData(el.id, flowActionModel, flow.edges, cusVars);
    }
    return {
      id: el.id,
      type: el.type as NodeType,
      flowAction: nodeData,
      position: { x: el.position.x | 0, y: el.position.y | 0 },
    };
  });
  const updatedTriggers = mapTriggersToData(flow, cusVars, convertDateToISO);

  const triggers: TriggerData[] = updatedTriggers.map(el => {
    if (el.typeDiscriminator === TriggerTypeDiscriminator.Schedule) {
      const isOnceTypeDiscriminator = el.scheduler?.typeDiscriminator === ScheduleTriggerTypeDiscriminator.once;
      if (isOnceTypeDiscriminator) {
        const value = formatDateTimeToUTC(el.scheduler?.scheduledTime ?? null);
        return {
          ...el,
          scheduler: {
            typeDiscriminator: el.scheduler?.typeDiscriminator,
            value,
          },
        };
      }
      const value = el.scheduler?.value;
      const startTime = formatDateTimeToUTC(el.scheduler?.startTime ?? null);
      const endTime = formatDateTimeToUTC(el.scheduler?.endTime ?? null);
      return {
        ...el,
        scheduler: {
          typeDiscriminator: el.scheduler?.typeDiscriminator,
          startTime,
          endTime,
          unit: el.scheduler?.unit,
          value,
        },
      };
    }
    return el;
  });

  const mappedState: FlowData = {
    locale: flow.locale,
    id: flow.newFlowId ?? flow.id,
    botId: flow.botId,
    title: flow.title,
    nodes: nodes,
    edges: edges,
    triggers: [...(triggers ?? [])],
  };
  return mappedState;
};

export const mapFlowToModel = (data: FlowData, customVar?: CustomVariablesModel[]): FlowModel => {
  const nodes: Node[] = data.nodes.map(el => {
    let flowAction: FlowActionModel | null = null;
    if (el.flowAction) {
      switch (el.flowAction.typeDiscriminator) {
        case FlowActionType.SendTextFlowAction:
          flowAction = mapActionDataSendText(el.flowAction as SendTextFlowActionData);
          break;
        case FlowActionType.QuestionFlowAction:
          flowAction = mapActionDataQuestion(el.flowAction as QuestionFlowActionData);
          break;
        case FlowActionType.SendPhotoFlowAction:
          flowAction = mapActionDataPhoto(el.flowAction as SendPhotoFlowActionData);
          break;
        case FlowActionType.SendDocumentFlowAction:
          flowAction = mapActionDataDocument(el.flowAction as SendDocumentFlowActionData);
          break;
        case FlowActionType.CurlFlowAction:
          flowAction = mapActionDataCurl(el.flowAction as CurlFlowActionData);
          break;
        case FlowActionType.ConditionFlowAction:
          flowAction = mapActionDataCondition(el.flowAction as ConditionFlowActionData, customVar as CustomVariablesModel[]);
          break;
        case FlowActionType.DelayFlowAction:
          flowAction = mapActionDataDelay(el.flowAction as DelayFlowActionData);
          break;
        case FlowActionType.AssignFlowAction:
          flowAction = mapActionDataAssign(el.flowAction as AssignFlowActionData);
          break;
        case FlowActionType.StartSubFlowAction:
          flowAction = mapActionDataStartFlow(el.flowAction as StartFlowActionData);
          break;
        case FlowActionType.SendVideoFlowAction:
          flowAction = mapActionDataVideo(el.flowAction as SendVideoFlowActionData);
          break;
        case FlowActionType.SendAppointmentFlowAction:
          flowAction = mapActionDataCalendar(el.flowAction as CalendarFlowActionData);
          break;
        case FlowActionType.SendPaymentFlowAction:
          flowAction = mapActionDataPayment(el.flowAction as PaymentFlowActionData);
          break;
        case FlowActionType.ShoppingCartFlowAction:
          flowAction = mapActionDataShoppingCart(el.flowAction as ShoppingCartFlowActionData);
          break;
        case FlowActionType.SendAudioFlowAction:
          flowAction = mapActionDataAudio(el.flowAction as SendAudioFlowActionData);
          break;
        case FlowActionType.SystemActionFlowAction:
        case FlowActionType.TakeItemFromArrayFlowAction:
          flowAction = el.flowAction as SystemActionFlowActionModel;
          break;
        case FlowActionType.SetVariableValueFlowAction:
        case FlowActionType.PutItemIntoArrayFlowAction:
          flowAction = mapSystemActionWithSourceTypeToModel(el.flowAction as SetVariableModel | PutItemIntoArrayModel);
          break;
        default:
          throw new Error("no type mapping for " + el.flowAction.typeDiscriminator);
      }
    }

    return {
      id: el.id,
      data: flowAction,
      type: el.type,
      position: { x: el.position?.x | 0, y: el.position?.y | 0 },
    };
  });

  const edges = data.edges.map(el => {
    const source = el.sourceId;
    const sourceNode = nodes.find(e => e.id === source);
    const flowAction = sourceNode?.data as FlowActionModel;

    let sourceHandle = el.sourceHandleId ?? "common-source";

    let isReplyKeyboardDetected = false;
    if (flowAction && (flowAction as ButtonsContainerFlowAction).replyMarkup) {
      isReplyKeyboardDetected =
        (flowAction as ButtonsContainerFlowAction).replyMarkup?.typeDiscriminator ===
        FlowMarkupTypeDiscriminator.ReplyKeyboardMarkup;
    }

    if (flowAction) {
      if (flowAction.typeDiscriminator === FlowActionType.CurlFlowAction) {
        const curlFlowAction = flowAction as CurlFlowActionModel;
        sourceHandle =
          el.targetId === curlFlowAction.successNodeId
            ? HandlePositiveTypes.ok
            : el.targetId === curlFlowAction.errorNodeId
            ? HandleNegativeTypes.error
            : "";
      }
    }

    const style =
      sourceHandle.includes(HandlePositiveTypes.ok) ||
      sourceHandle.includes(HandlePositiveTypes.true) ||
      sourceHandle.includes(HandlePositiveTypes["success-source"])
        ? { style: { stroke: "#4EAA4A" } }
        : HandleNegativeTypes.hasOwnProperty(sourceHandle)
        ? { style: { stroke: "#EB5038" } }
        : {};
    const replyStyle = { style: { stroke: "#325EE6" } };

    if (isReplyKeyboardDetected && sourceHandle !== "common-source" && sourceHandle !== "success-source") {
      return {
        id: el.id,
        source,
        sourceHandle,
        ...(replyStyle.style && replyStyle),
        target: el.targetId,
        markerEnd: {
          type: MarkerType.ArrowClosed,
          ...(replyStyle.style && { color: replyStyle.style.stroke }),
        },
      };
    }
    return {
      id: el.id,
      source,
      sourceHandle,
      ...(style.style && style),
      target: el.targetId,
      markerEnd: {
        type: MarkerType.ArrowClosed,
        ...(style.style && { color: style.style.stroke }),
      },
    };
  });

  const triggers = data.triggers.map(el => {
    if (el.typeDiscriminator === TriggerTypeDiscriminator.Schedule) {
      const triggerScheduler = el as SchedulerTriggerData;
      const schedulerTypeDiscriminator = triggerScheduler.scheduler.typeDiscriminator;
      const isOnceTypeDiscriminator = schedulerTypeDiscriminator === ScheduleTriggerTypeDiscriminator.once;
      let scheduledTime;
      let scheduledValue;
      if (isOnceTypeDiscriminator) {
        scheduledTime = isOnceTypeDiscriminator ? formatDateTimeToString(new Date(triggerScheduler.scheduler.value)) : null;
        scheduledValue = undefined;
      } else {
        scheduledTime = null;
        scheduledValue = triggerScheduler.scheduler.value;
      }

      return { ...el, scheduler: { ...triggerScheduler.scheduler, scheduledTime, value: scheduledValue } };
    }
    return el;
  });

  const mappedState: FlowModel = {
    id: data.id,
    title: data.title,
    nodes: nodes,
    edges: edges,
    triggers,
    botId: data.botId,
    locale: data.locale,
  };

  return mappedState;
};

export const getNodeVariableIds = (text: string, vars: CustomVariablesModel[]): string[] => {
  const regex = /@{([\wа-яА-ЯёЁ-]+):([\wа-яА-ЯёЁ-]+)}/g;
  const variables = text.match(regex);
  if (!variables) {
    return [];
  }

  const variableIds = variables
    .map(el => {
      const scopeAndKey = el.replace(regex, "$1:$2");
      const [scope, key] = scopeAndKey.split(":");
      const customVariable = vars.find(el => key && el.key === key && el.scope === scope);
      return customVariable?.id ?? "";
    })
    .filter(x => x.length > 0);
  return variableIds;
};

export const mapToFileInfoModel = (data: FileData): FileInfoModel => {
  return {
    ...data,
  };
};

const mapActionDataCurl = (flowActionData: CurlFlowActionData): CurlFlowActionModel => {
  return {
    ...flowActionData,
  };
};

const mapActionDataCondition = (
  flowActionData: ConditionFlowActionData,
  customVar: CustomVariablesModel[],
): ConditionFlowActionModel => {
  const updatedConditions = flowActionData.conditions.map(condition => {
    const customVarType = customVar?.find(
      variable => variable.id === condition.conditionCustomVariableId && variable.type === "DateTime",
    );
    if (customVarType) {
      return {
        ...condition,
        value: convertDateIsotoLocal(condition.value),
      };
    }
    return condition;
  });

  return {
    ...flowActionData,
    conditions: updatedConditions,
  };
};

const mapActionDataDelay = (flowActionData: DelayFlowActionData): DelayFlowActionModel => {
  const value = flowActionData.value ? flowActionData.value.toString() : "";
  return {
    ...flowActionData,
    value,
  };
};

const mapActionDataAssign = (flowActionData: AssignFlowActionData): AssignFlowActionModel => {
  return {
    ...flowActionData,
  };
};

const mapActionDataStartFlow = (flowActionData: StartFlowActionData): StartFlowActionModel => {
  return {
    ...flowActionData,
  };
};

const mapActionDataSendText = (flowActionData: SendTextFlowActionData): SendTextFlowActionModel => {
  if (flowActionData.replyMarkup) {
    return {
      ...flowActionData,
      replyMarkup: mapButtonsDataToModel((flowActionData as ButtonsContainerFlowAction).replyMarkup),
    };
  }
  return {
    ...flowActionData,
  };
};

const mapActionDataQuestion = (flowActionData: QuestionFlowActionData): QuestionFlowActionModel => {
  return {
    ...flowActionData,
  };
};

const mapActionDataPhoto = (flowActionData: SendPhotoFlowActionData): SendPhotoFlowActionModel => {
  if (flowActionData.replyMarkup) {
    return {
      ...flowActionData,
      replyMarkup: mapButtonsDataToModel(flowActionData.replyMarkup),
    };
  }
  return {
    ...flowActionData,
  };
};

const mapActionDataDocument = (flowActionData: SendDocumentFlowActionData): SendDocumentFlowActionModel => {
  if (flowActionData.replyMarkup) {
    return {
      ...flowActionData,
      replyMarkup: mapButtonsDataToModel(flowActionData.replyMarkup),
    };
  }
  return {
    ...flowActionData,
  };
};

const mapActionDataVideo = (flowActionData: SendVideoFlowActionData): SendVideoFlowActionModel => {
  if (flowActionData.replyMarkup) {
    return {
      ...flowActionData,
      replyMarkup: mapButtonsDataToModel(flowActionData.replyMarkup),
    };
  }
  return {
    ...flowActionData,
  };
};

const mapActionDataCalendar = (flowActionData: CalendarFlowActionData): CalendarFlowActionModel => {
  const wokringTimeNew: WorkingTimeModel[] = [];
  flowActionData.schedule?.workTime.forEach((el, i) => {
    wokringTimeNew.push({ ...el, order: chooseDay(el.dayOfWeek) });
  });

  return {
    ...flowActionData,
    workingTimes: wokringTimeNew,
    isAvailable: !!flowActionData.schedule?.workTime,
  };
};

const mapActionDataPayment = (flowActionData: PaymentFlowActionData): PaymentFlowActionModel => {
  const details = [];
  flowActionData.order.needEmail && details.push("Email");
  flowActionData.order.needName && details.push("Name");
  flowActionData.order.needPhoneNumber && details.push("Phone");
  flowActionData.order.needShippingAddress && details.push("Address");
  return {
    ...flowActionData,
    text: flowActionData.title,
    price: { ...flowActionData.price, amount: flowActionData.price.amount },
    gap: { hours: flowActionData.duration?.hoursValue || 0, minutes: flowActionData.duration?.minutesValue || 0 },
    order: {
      isAll:
        flowActionData.order.needEmail &&
        flowActionData.order.needName &&
        flowActionData.order.needPhoneNumber &&
        flowActionData.order.needShippingAddress,
      needEmail: flowActionData.order.needEmail,
      needName: flowActionData.order.needName,
      needPhoneNumber: flowActionData.order.needPhoneNumber,
      needShippingAddress: flowActionData.order.needShippingAddress,
    },
    fileUrl: flowActionData.image?.imageUrl || "",
    details: details,
    paymentProvider: flowActionData.paymentProvider,
  };
};

const mapActionDataShoppingCart = (flowActionData: ShoppingCartFlowActionData): ShoppingCartFlowActionModel => {
  const reply = flowActionData.replyMarkup as InlineKeyboardMarkupModel;
  const resultButtons: ReplyKeyboardButtonModel[][] = [];
  reply.buttons.forEach(el => {
    const button = el[0] as unknown as ReplyKeyboardButtonModel;
    if (button.typeDiscriminator === ButtonTypeDiscriminator.FlowMessageShoppingCartButton) {
      resultButtons.push([{ ...button, typeDiscriminator: ButtonTypeDiscriminator.FlowReplyKeyboardButton, requestOrder: true }]);
    } else {
      resultButtons.push([button]);
    }
  });
  const resultMarkup: InlineKeyboardMarkupModel = {
    ...flowActionData.replyMarkup,
    typeDiscriminator: flowActionData.replyMarkup?.typeDiscriminator
      ? flowActionData.replyMarkup?.typeDiscriminator
      : FlowMarkupTypeDiscriminator.ReplyKeyboardMarkup,
    buttons: resultButtons as unknown as ButtonModel[][],
  };
  return {
    ...flowActionData,
    text: flowActionData.text,
    replyMarkup: resultMarkup,
  };
};

const mapActionDataAudio = (flowActionData: SendAudioFlowActionData): SendAudioFlowActionModel => {
  if (flowActionData.replyMarkup) {
    return {
      ...flowActionData,
      replyMarkup: mapButtonsDataToModel(flowActionData.replyMarkup),
    };
  }
  return {
    ...flowActionData,
  };
};

export const mapFlowActionToData = (
  nodeId: string,
  flowAction: FlowActionModel,
  edges: Edge[],
  cusVars: CustomVariablesModel[],
  fileUrlCusVars?: CustomVariablesModel[] | null,
): FlowActionData => {
  switch (flowAction.typeDiscriminator) {
    case FlowActionType.SendTextFlowAction:
      return mapSendTextActionToData(flowAction as SendDocumentFlowActionModel, edges, cusVars);
    case FlowActionType.QuestionFlowAction:
      return mapQuestionFlowActionToData(flowAction as QuestionFlowActionModel, edges, cusVars);
    case FlowActionType.SendPhotoFlowAction:
      return mapSendPhotoActionToData(flowAction as SendPhotoFlowActionModel, edges, cusVars);
    case FlowActionType.SendDocumentFlowAction:
      return mapSendDocumentActionToData(flowAction as SendDocumentFlowActionModel, edges, cusVars);
    case FlowActionType.CurlFlowAction:
      return mapCurlFlowActionToData(flowAction as CurlFlowActionModel, nodeId, edges, cusVars);
    case FlowActionType.ConditionFlowAction:
      return mapConditionActionToData(flowAction as ConditionFlowActionModel, nodeId, edges, cusVars);
    case FlowActionType.DelayFlowAction:
      return mapDelayActionToData(flowAction as DelayFlowActionModel);
    case FlowActionType.AssignFlowAction:
      return mapAssignActionToData(flowAction as AssignFlowActionModel);
    case FlowActionType.StartSubFlowAction:
      return mapStartFlowActionToData(flowAction as StartFlowActionModel);
    case FlowActionType.SendAppointmentFlowAction:
      return mapCalendarActionToData(flowAction as CalendarFlowActionModel, cusVars);
    case FlowActionType.SendPaymentFlowAction:
      return mapPaymentActionToData(flowAction as PaymentFlowActionModel, nodeId, edges, cusVars);
    case FlowActionType.ShoppingCartFlowAction:
      return mapShoppingCartActionToData(flowAction as ShoppingCartFlowActionModel, nodeId, edges, cusVars);
    case FlowActionType.SystemActionFlowAction:
    case FlowActionType.TakeItemFromArrayFlowAction:
      return flowAction as SystemActionFlowActionModel;
    case FlowActionType.SetVariableValueFlowAction:
    case FlowActionType.PutItemIntoArrayFlowAction:
      return mapSystemActionWithSourceTypeToData(flowAction as SetVariableModel | PutItemIntoArrayModel);
    case FlowActionType.SendVideoFlowAction:
      return mapSendVideoActionToData(flowAction as SendVideoFlowActionModel, edges, cusVars);
    case FlowActionType.SendAudioFlowAction:
      return mapSendAudioActionToData(flowAction as SendVideoFlowActionModel, edges, cusVars);
    default:
      throw new Error("No type mapping");
  }
};

const mapButtonsDataToModel = (replyData?: ReplyMarkupData) => {
  if (replyData) {
    const data =
      replyData.typeDiscriminator === FlowMarkupTypeDiscriminator.InlineKeyboardMarkup
        ? (replyData as InlineKeyboardMarkupModel)
        : (replyData as ReplyKeyboardMarkupModel);

    const mappedButtons = data.buttons?.map(el =>
      el.map(el => {
        if (
          el.typeDiscriminator ===
          (ButtonTypeDiscriminator.FlowMessageCallbackButton || ButtonTypeDiscriminator.FlowMessageUrlButton)
        ) {
          el.type = el.url ? ButtonType.UrlButton : ButtonType.CallbackButton;
        }
        return el;
      }),
    );
    if (!mappedButtons) {
      const replyKeyboardModel: ReplyMarkupModel = {
        typeDiscriminator: replyData.typeDiscriminator,
      };
      return replyKeyboardModel;
    }
    if (data.typeDiscriminator === FlowMarkupTypeDiscriminator.InlineKeyboardMarkup) {
      const inlineKeyboardModel: InlineKeyboardMarkupModel = {
        buttons: mappedButtons as ButtonModel[][],
        typeDiscriminator: FlowMarkupTypeDiscriminator.InlineKeyboardMarkup,
      };
      return inlineKeyboardModel;
    }
    if (data.typeDiscriminator === FlowMarkupTypeDiscriminator.ReplyKeyboardMarkup) {
      const replyKeyboardModel: ReplyKeyboardMarkupModel = {
        buttons: mappedButtons as ReplyKeyboardButtonModel[][],
        typeDiscriminator: FlowMarkupTypeDiscriminator.ReplyKeyboardMarkup,
      };
      return replyKeyboardModel;
    }
  } else {
    return;
  }
};

const mapNodeButtonsToData = (edges: Edge[], replyButtons?: ReplyMarkupModel): NodeButtonData[] | undefined => {
  if (!replyButtons) {
    return;
  }
  const reply =
    replyButtons.typeDiscriminator === FlowMarkupTypeDiscriminator.InlineKeyboardMarkup
      ? (replyButtons as InlineKeyboardMarkupModel)
      : (replyButtons as ReplyKeyboardMarkupModel);
  if (!reply.buttons) {
    return;
  }

  const nodeButtons: NodeButtonData[] = reply.buttons.map(element =>
    element.map(elem => {
      const targetEdge = edges.find(e => e.sourceHandle === elem.id);
      //
      return {
        ...elem,
        label: elem.label ?? "",
        targetNodeId: targetEdge?.target ?? "",
        payload: elem.payload ?? "",
        typeDiscriminator: (elem as ReplyKeyboardButtonModel).requestOrder
          ? ButtonTypeDiscriminator.FlowMessageShoppingCartButton
          : elem.typeDiscriminator,
      };
    }),
  );
  return nodeButtons;
};

function getReplyMarkup(nodes: NodeButtonData[] | undefined): ReplyMarkupData | undefined {
  if (!nodes) {
    return;
  }
  const isReply = nodes.find(node =>
    (node as ReplyButtonData[]).find(
      button =>
        button.typeDiscriminator === ButtonTypeDiscriminator.FlowReplyKeyboardButton ||
        button.typeDiscriminator === ButtonTypeDiscriminator.FlowMessageShoppingCartButton,
    ),
  );
  if (isReply) {
    const reply: ReplyKeyboardMarkupData = {
      buttons: nodes as ReplyKeyboardButtonModel[][],
      typeDiscriminator: FlowMarkupTypeDiscriminator.ReplyKeyboardMarkup,
    };
    return reply;
  } else {
    const inline: InlineKeyboardMarkupData = {
      buttons: nodes as ButtonModel[][],
      typeDiscriminator: FlowMarkupTypeDiscriminator.InlineKeyboardMarkup,
    };
    return inline;
  }
}

const mapQuestionFlowActionToData = (flowAction: QuestionFlowActionModel, edges: Edge[], cusVars: CustomVariablesModel[]) => {
  return {
    ...flowAction,
    customVariableIds: mapCustomVariableIds(flowAction.text, cusVars),
  } as QuestionFlowActionData;
};

const mapSendTextActionToData = (flowAction: SendTextFlowActionModel, edges: Edge[], cusVars: CustomVariablesModel[]) => {
  return {
    ...flowAction,
    customVariableIds: mapCustomVariableIds(flowAction.text, cusVars),
    replyMarkup: getReplyMarkup(mapNodeButtonsToData(edges, flowAction.replyMarkup)),
  } as SendTextFlowActionData;
};

const mapSendPhotoActionToData = (flowAction: SendPhotoFlowActionModel, edges: Edge[], cusVars: CustomVariablesModel[]) => {
  return {
    ...flowAction,
    customVariableIds: mapCustomVariableIds(flowAction.text, cusVars),
    fileUrlCustomVariableIds: mapCustomVariableIds(flowAction.fileUrl, cusVars),
    replyMarkup: getReplyMarkup(mapNodeButtonsToData(edges, flowAction.replyMarkup)),
  } as SendPhotoFlowActionData;
};

const mapSendDocumentActionToData = (flowAction: SendDocumentFlowActionModel, edges: Edge[], cusVars: CustomVariablesModel[]) => {
  return {
    ...flowAction,
    customVariableIds: mapCustomVariableIds(flowAction.text, cusVars),
    replyMarkup: getReplyMarkup(mapNodeButtonsToData(edges, flowAction.replyMarkup)),
  } as SendDocumentFlowActionData;
};

const mapCurlFlowActionToData = (
  flowAction: CurlFlowActionModel,
  nodeId: string,
  edges: Edge[],
  cusVars: CustomVariablesModel[],
) => {
  const successEdge = edges.find(
    e => e?.sourceHandle && HandlePositiveTypes.hasOwnProperty(e?.sourceHandle) && e.source === nodeId,
  );
  const errorEdge = edges.find(
    e => e?.sourceHandle && HandleNegativeTypes.hasOwnProperty(e?.sourceHandle) && e.source === nodeId,
  );

  return {
    ...flowAction,
    customVariableIds: mapCustomVariableIds(flowAction.curlCommand, cusVars),
    successNodeId: successEdge?.target,
    errorNodeId: errorEdge?.target,
  } as CurlFlowActionData;
};
const mapConditionActionToData = (
  flowAction: ConditionFlowActionModel,
  nodeId: string,
  edges: Edge[],
  customVar: CustomVariablesModel[],
) => {
  const conditions = flowAction.conditions.map(el => {
    const custVar = customVar?.find(
      variable =>
        variable.id === el.conditionCustomVariableId &&
        (variable.scope === "Contact" || variable.scope === "Bot") &&
        variable.type === "DateTime",
    );
    const targetNodeId = edges.find(edge => edge.sourceHandle === `${el.id}${HandlePositiveTypes.true}`)?.target;
    const value = el.value && custVar ? convertDateToISO(el.value) : el.value;
    return {
      ...el,
      targetNodeId,
      value,
    };
  });
  const errorEdge = edges.find(
    e => e?.sourceHandle && HandleNegativeTypes.hasOwnProperty(e?.sourceHandle) && e.source === nodeId,
  );

  return {
    ...flowAction,
    conditions,
    errorNodeId: errorEdge?.target,
  } as ConditionFlowActionData;
};

const mapDelayActionToData = (flowAction: DelayFlowActionModel) => {
  const value = flowAction.value ? parseInt(flowAction.value) : 0;
  return {
    ...flowAction,
    value,
  } as DelayFlowActionData;
};

const mapAssignActionToData = (flowAction: AssignFlowActionModel) => {
  return {
    ...flowAction,
  } as AssignFlowActionData;
};

const mapStartFlowActionToData = (flowAction: StartFlowActionModel) => {
  return {
    typeDiscriminator: flowAction.typeDiscriminator,
    title: flowAction.title,
    flowId: flowAction.flowId,
  } as StartFlowActionData;
};

const mapCalendarActionToData = (flowAction: CalendarFlowActionModel, cusVars: CustomVariablesModel[]) => {
  return {
    typeDiscriminator: flowAction.typeDiscriminator,
    text: flowAction.text,
    targetCustomVariableId: flowAction.targetCustomVariableId,
    gap: {
      hours: flowAction.gap?.hours ? Number(flowAction.gap?.hours) : 0,
      minutes: flowAction.gap?.minutes ? Number(flowAction.gap?.minutes) : 0,
    },
    schedule: flowAction.workingTimes?.length ? { workTime: flowAction.workingTimes } : null,
    isFallback: flowAction.isFallback,
    retryCount: flowAction.retryCount,
    fallbackMessage: flowAction.fallbackMessage,
    customVariableIds: mapCustomVariableIds(flowAction.text, cusVars),
  } as CalendarFlowActionData;
};

const mapPaymentActionToData = (
  flowAction: PaymentFlowActionModel,
  nodeId: string,
  edges: Edge[],
  cusVars: CustomVariablesModel[],
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { file, fileId, fileUrl, fileInfo, details, text, gap, ...data } = flowAction;

  const successEdge = edges.find(e => e?.sourceHandle && e.sourceHandle === "success-source" && e.source === nodeId);
  const defaultEdge = edges.find(e => e?.sourceHandle && e.sourceHandle === "common-source" && e.source === nodeId);
  return {
    ...data,
    title: flowAction.text,
    duration: { hoursValue: flowAction.gap?.hours, minutesValue: flowAction.gap?.minutes },
    order: {
      needEmail: data.order ? data.order.needEmail : false,
      needName: data.order ? data.order.needName : false,
      needPhoneNumber: data.order ? data.order.needPhoneNumber : false,
      needShippingAddress: data.order ? data.order.needShippingAddress : false,
    },
    image: flowAction.fileUrl ? { imageUrl: flowAction.fileUrl } : null,
    defaultNodeId: defaultEdge?.target,
    successNodeId: successEdge?.target,
    price: { ...flowAction.price, amount: flowAction.price?.amount || "" },
    targetCustomVariablesIds: mapCustomVariableIds(
      String(flowAction.text) +
        String(flowAction.description) +
        String(flowAction.accessToken) +
        String(flowAction.price?.amount || ""),
      cusVars,
    ),
  } as PaymentFlowActionData;
};

const mapShoppingCartActionToData = (
  flowAction: ShoppingCartFlowActionModel,
  nodeId: string,
  edges: Edge[],
  cusVars: CustomVariablesModel[],
) => {
  const successEdge = edges.find(e => e?.sourceHandle && e.sourceHandle === "success-source" && e.source === nodeId);
  const defaultEdge = edges.find(e => e?.sourceHandle && e.sourceHandle === "common-source" && e.source === nodeId);

  return {
    ...flowAction,
    title: flowAction.text,
    defaultNodeId: defaultEdge?.target,
    successNodeId: successEdge?.target,
    customVariableIds: mapCustomVariableIds(flowAction.text, cusVars),
    replyMarkup: getReplyMarkup(mapNodeButtonsToData(edges, flowAction.replyMarkup)),
  } as ShoppingCartFlowActionData;
};

const mapSendVideoActionToData = (flowAction: SendVideoFlowActionModel, edges: Edge[], cusVars: CustomVariablesModel[]) => {
  return {
    ...flowAction,
    customVariableIds: mapCustomVariableIds(flowAction.text, cusVars),
    replyMarkup: getReplyMarkup(mapNodeButtonsToData(edges, flowAction.replyMarkup)),
  } as SendVideoFlowActionData;
};

const mapSendAudioActionToData = (flowAction: SendAudioFlowActionModel, edges: Edge[], cusVars: CustomVariablesModel[]) => {
  return {
    ...flowAction,
    customVariableIds: mapCustomVariableIds(flowAction.text, cusVars),
    replyMarkup: getReplyMarkup(mapNodeButtonsToData(edges, flowAction.replyMarkup)),
  } as SendAudioFlowActionData;
};

const mapSystemActionWithSourceTypeToData = (flowAction: SetVariableModel | PutItemIntoArrayModel) => {
  return {
    ...flowAction,
    sourceCustomVariableId: flowAction.sourceType === "variable" ? flowAction.sourceCustomVariableId : undefined,
    value: flowAction.sourceType === "value" ? flowAction.value : undefined,
  };
};

const mapSystemActionWithSourceTypeToModel = (flowAction: SetVariableModel | PutItemIntoArrayModel) => {
  const getSourceType = (flowAction: SetVariableModel | PutItemIntoArrayModel) => {
    if (flowAction?.sourceType) {
      return flowAction.sourceType;
    } else if (flowAction.sourceCustomVariableId) {
      return "variable";
    } else if (flowAction.value) {
      return "value";
    }
    return "variable";
  };

  return {
    ...flowAction,
    sourceType: getSourceType(flowAction),
  };
};

const mapCustomVariableIds = (text: string | undefined, customVariables: CustomVariablesModel[]): string[] => {
  if (!text) {
    return [];
  }
  const customVariableIds = getNodeVariableIds(text, customVariables);
  return customVariableIds;
};

export const mapToNodeModel = (node: Node): NodeModel => {
  return {
    id: node.id,
    type: node.type as NodeType,
    flowAction: node.data,
    position: node.position,
  };
};
