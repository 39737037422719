import React from "react";
import { Text, Flex, Icon } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Inbox } from "../../../assets/icons/inbox.svg";
import s from "./EmailConfirmationContainer.module.scss";

interface Props {
  email?: string;
}

export const EmailConfirmationContainer = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "auth" });
  const emailAdress = t("Email confirmation description", {
    email: props.email,
  });

  return (
    <Flex className={s.emailConfirmationContainer}>
      <Flex className={s.emailConfirmationTitle}>
        <Icon as={Inbox} boxSize="80px" />
        <Text className={s.emailConfirmationText}>{t("Check your mail")}</Text>
      </Flex>
      <Text className={s.emailConfirmationDescription}>
        {emailAdress.split(props.email!).map((part, index) => (
          <React.Fragment key={index}>
            {part}
            {index < emailAdress.split(props.email!).length - 1 && (
              <Text as="span" color="mainPurple">
                {props.email}
              </Text>
            )}
          </React.Fragment>
        ))}
      </Text>
    </Flex>
  );
};
