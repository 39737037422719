import { getFingerprint } from "react-fingerprint";
import { AppSettings } from "../../common/AppSettings";
import { BadRequestError, Error400Model, Error403Model } from "../../common/ErrorModel";
import { LanguageCodeEnum } from "../../common/user/UserModel";
import { SignupModel } from "./SignUpModel";

declare const appSettings: AppSettings;

export const SignUp = async (data: SignupModel, lng: LanguageCodeEnum) => {
  const res = await fetch(`${appSettings.apiIdentityProvider}/signUp`, {
    method: "POST",
    headers: {
      Accept: "application/vnd.domino.v1+json",
      "Content-Type": "application/vnd.domino.v1+json",
      Pragma: "no-cache",
      "Cache-Control": "no-cache",
      "x-domino-lang": lng,
    },
    body: data ? JSON.stringify(data) : undefined,
  });

  if (res?.status === 400) {
    const data: Error400Model = await res.json();
    throw new BadRequestError(data?.uiMessage ?? data.message);
  }

  if (res.status === 200) {
    return res.status;
  }
};

export const signUpConfirmationApi = async (token: string, lng: LanguageCodeEnum) => {
  const fingerprint = await getFingerprint();

  const result = await fetch(`${appSettings.apiIdentityProvider}/confirmEmail`, {
    method: "POST",
    headers: {
      Authorization: "Bearer " + token ?? "",
      Accept: "application/vnd.domino.v1+json",
      "Content-Type": `application/vnd.domino.v1+json`,
      Pragma: "no-cache",
      "Cache-Control": "no-cache",
      "x-domino-lang": lng,
    },
    credentials: "include",
    body: JSON.stringify({ fingerprint: fingerprint }),
  });

  if (result?.status === 400) {
    const data: Error400Model = await result.json();
    throw new BadRequestError(data?.uiMessage ?? data.message);
  }

  if (result?.status === 403) {
    const error = { message: "notApproved" };
    throw new Error403Model(error.message);
  }

  if (result.status === 200) {
    return result.json();
  }
};

//401 - если протухла
