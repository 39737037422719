import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import s from "./UserProfile.module.scss";
import { useAppDispatch, useAppSelector } from "../../common/state/store";
import { LanguageCodeEnum } from "../../common/user/UserModel";
import {
  changePassword,
  selectNotificationSettingsInUserProfile,
  selectNotificationBotsInUserProfile,
  selectCurrentOrganization,
  getUserProfileNotifications,
  setUserProfile,
  setUserProfileNotifications,
} from "./UserProfileSlice";
import { useTranslation } from "react-i18next";
import { PATTERN_FOR_PASSWORD } from "../../common/Regexp";
import { selectUser } from "../../common/user/UserSlice";
import { getBots, selectBots } from "../dashboard/DashboardSlice";
import { UserProfilePushNotifications } from "./components/pushNotifications/UserProfilePushNotifications";
import { NotificationSettingsModel, UserProfileNotificationsModel } from "./UserProfileModel";
import { ReactComponent as EyeIcon } from "../../assets/icons/eyeIcon.svg";
import { ReactComponent as EyeClosedIcon } from "../../assets/icons/eyeClosedIcon.svg";
import { selectIsOrganizationTrial } from "../organisation/OrganisationSlice";
//import { selectIsOrganizationTrial } from "../organisation/OrganisationSlice";

export const UserProfileContainer = () => {
  const { t } = useTranslation("translation", { keyPrefix: "userProfile" });
  const userProfile = useAppSelector(selectUser);
  const currentOrganization = useAppSelector(selectCurrentOrganization);
  const notifications = useAppSelector(selectNotificationSettingsInUserProfile);
  const telegramBots = useAppSelector(selectNotificationBotsInUserProfile);
  const dispatch = useAppDispatch();
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const isPasswordsNotEqual = Boolean(newPassword && confirmNewPassword && newPassword !== confirmNewPassword);
  const isInvalidPassword = newPassword.length > 0 && (!PATTERN_FOR_PASSWORD.test(newPassword) || newPassword.length < 8);
  const isTrial = useAppSelector(selectIsOrganizationTrial);
  const isChangePasswordDisabled =
    (!currentPassword && userProfile?.hasPassword) ||
    !newPassword ||
    !confirmNewPassword ||
    isPasswordsNotEqual ||
    isInvalidPassword;

  const bots = useAppSelector(selectBots);
  useEffect(() => {
    getPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const passwordsData = [
    userProfile?.hasPassword && {
      title: t("Current Password"),
      value: currentPassword,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentPassword(e.target.value);
      },
      isInvalid: false,
    },
    {
      title: t("New Password"),
      value: newPassword,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword(e.target.value);
      },
      isInvalid: isInvalidPassword,
      tipText: t(`Password must be`),
    },
    {
      title: t("Confirm New Password"),
      value: confirmNewPassword,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmNewPassword(e.target.value);
      },
      isInvalid: isPasswordsNotEqual,
    },
  ];
  const showPasswordsArray = Array(passwordsData.length).fill(false);
  const [showPasswords, setShowPasswords] = useState(showPasswordsArray);

  const handleClick = (index: number) => {
    setShowPasswords(prev => prev.map((show, i) => (i === index ? !show : show)));
  };

  const getPage = (page: number) => {
    dispatch(getBots(page));
    dispatch(getUserProfileNotifications());
  };

  const changeLanguage = (language: LanguageCodeEnum) => () => {
    if (userProfile) {
      dispatch(
        setUserProfile({
          ...userProfile,
          locale: language,
        }),
      );
    }
  };
  if (!userProfile.id) {
    return <></>;
  }
  const changeDominoPush = (notificationSettings: NotificationSettingsModel[]) => {
    if (notificationSettings && telegramBots) {
      const settings: UserProfileNotificationsModel = {
        notifications: notificationSettings,
        telegramBots: telegramBots,
      };
      dispatch(setUserProfileNotifications(settings));
    }
  };

  const onChangePasswordClick = () => {
    dispatch(changePassword({ currentPassword, newPassword }));
    setCurrentPassword("");
    setNewPassword("");
    setConfirmNewPassword("");
    setShowPasswords(showPasswordsArray);
  };

  function getContentItemTitle(text: string) {
    return (
      <div className={s.contentItemTitle}>
        <Text>{t(text)}</Text>
      </div>
    );
  }

  return (
    <Box
      className={s.userProfileLayout}
      height={
        window.innerWidth > 768
          ? "calc(100dvh - 60px)"
          : window.innerWidth <= 768 && isTrial
          ? "calc(100dvh - 60px - 60px - 60px)"
          : "calc(100dvh - 120px)"
      }
      overflowY={"scroll"}
    >
      <div className={s.mainInfoWrapper}>
        {getContentItemTitle("Main settings")}
        <div className={s.contentItem}>
          <Text width="100%" maxWidth="300px" variant="large">
            {t("Email")}
          </Text>
          <Text variant="medium">{userProfile.email ?? <span color={"#8592A3"}>{t("Not specified")}</span>}</Text>
        </div>
        <div className={s.contentItem}>
          <Text width="100%" maxWidth="300px" variant="large">
            {t("Username")}
          </Text>
          <Text variant="medium">{userProfile?.username}</Text>
        </div>
        <div className={s.contentItem}>
          <Text width="100%" maxWidth="300px" variant="large">
            {t("Language")}
          </Text>
          <Button
            variant="dominoGhostLng"
            ml="-16px"
            mr="7px"
            isActive={userProfile.locale === LanguageCodeEnum.en}
            onClick={changeLanguage(LanguageCodeEnum.en)}
          >
            English
          </Button>
          <Button
            variant="dominoGhostLng"
            mx="7px"
            isActive={userProfile.locale === LanguageCodeEnum.ru}
            onClick={changeLanguage(LanguageCodeEnum.ru)}
          >
            Русский
          </Button>
        </div>
      </div>
      <div className={s.mainInfoWrapper}>
        <UserProfilePushNotifications
          changeDominoPush={changeDominoPush}
          userProfile={userProfile}
          notifications={notifications}
          bots={bots}
          telegramBots={telegramBots}
          getPage={getPage}
          currentOrganization={currentOrganization ?? ""}
        />
      </div>
      <div className={s.mainInfoWrapper}>
        {getContentItemTitle("Change password")}
        <div className={s.changePasswordContent1}>
          {passwordsData.map((passwordLine, index) => {
            return (
              passwordLine && (
                <div key={`${passwordLine.title}${index}`} className={s.contentItem}>
                  <Text py="8px" width="100%" maxWidth="300px" variant="large">
                    {passwordLine.title}
                  </Text>
                  <FormControl display="flex" className={s.changePasswordFormControl} isInvalid={passwordLine.isInvalid}>
                    <InputGroup className={s.inputGroup}>
                      <Input
                        className={s.input}
                        type={showPasswords[index] ? "text" : "password"}
                        value={passwordLine.value}
                        onChange={passwordLine.onChange}
                      />
                      {passwordLine.value && (
                        <InputRightElement className={s.inputRightElement}>
                          <Button className={s.passIcon} onClick={() => handleClick(index)}>
                            {showPasswords[index] ? (
                              <EyeClosedIcon className={s.eyeClosedIcon} title={`${t("Hide password")}`} />
                            ) : (
                              <EyeIcon className={s.eyeIcon} title={`${t("Show password")}`} />
                            )}
                          </Button>
                        </InputRightElement>
                      )}
                    </InputGroup>

                    {passwordLine.isInvalid && !passwordLine.tipText && (
                      <Alert className={s.alert} height="36px" status="error" maxW="380px">
                        <AlertIcon />
                        <Box>
                          <AlertDescription>{t("Passwords don’t match")}</AlertDescription>
                        </Box>
                      </Alert>
                    )}
                    {passwordLine.tipText && (
                      <Text
                        className={s.tip}
                        width="100%"
                        maxW="385px"
                        variant="small"
                        color={passwordLine.isInvalid ? "mainRed" : "darkGrey"}
                      >
                        {passwordLine.tipText}
                      </Text>
                    )}
                  </FormControl>
                </div>
              )
            );
          })}
        </div>
        <div className={s.contentItem}>
          <Flex width="100%" maxWidth="680px" justify="end">
            <Button isDisabled={isChangePasswordDisabled} onClick={onChangePasswordClick} size="sm" variant="dominoViolet">
              {t("Change password")}
            </Button>
          </Flex>
        </div>
      </div>
    </Box>
  );
};
