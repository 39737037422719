import { SetStateAction, useState, useEffect, useRef } from "react";
import { FlowItemModel } from "../../AutomationModel";
import { Box, Card, CardBody, Input, Text, Tooltip } from "@chakra-ui/react";
import s from "../../Automation.module.scss";
import { fromNow } from "../../../../common/utils/fromNow";
import { ReactComponent as EditIcon } from "../../../../assets/icons/editIcon.svg";
import { useAppDispatch } from "../../../../common/state/store";
import { FlowListItemMenu } from "./components/FlowListItemMenu";
import { getExportFlow, renameFlow } from "../../AutomationSlice";
import { useTranslation } from "react-i18next";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { ReactComponent as DoubleArrowIcon } from "../../../../assets/icons/automationDoubleArrowIcon.svg";
import { FormattedDateTooltip } from "../../../../UI/atoms/formattedDateTooltip/FormattedDateTooltip";
import { useGAWithCustomParameters } from "../../../../common/ga/GAEventTracker";

interface Props {
  id: string;
  IsExportAvailable: any /* eslint-disable-line @typescript-eslint/no-explicit-any */;
  IsUnlimited: any /* eslint-disable-line @typescript-eslint/no-explicit-any */;
  accessibilityNodeCount: any /* eslint-disable-line @typescript-eslint/no-explicit-any */;
  canAddFlow: any /* eslint-disable-line @typescript-eslint/no-explicit-any */;
  isDrag?: boolean;
  isDesktop: boolean;
  showReorder: boolean;
  hideArrow: boolean;
  flowItem: FlowItemModel;
  lng: string;
  onCopy: (flow: FlowItemModel) => void;
  onDelete: (flow: FlowItemModel) => void;
  selectFlow: (currentFlow: FlowItemModel) => void;
}

export const FlowListItem = (props: Props) => {
  const [isEditFlowName, setIsEditFlowName] = useState(false);
  const [currentText, setCurrentText] = useState("");
  const [lastText, setLastText] = useState("");
  const [focusOnInput, setFocusOnInput] = useState(false);
  const [focusOnCard, setFocusOnCard] = useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation("translation", { keyPrefix: "flowList" });
  const tp = useTranslation("translation", { keyPrefix: "tariffPlan" }).t;
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });
  const trackEvent = useGAWithCustomParameters("Flow");

  useEffect(() => {
    setCurrentText(props.flowItem.title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.flowItem.title]);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const onChange = (event: { target: { value: SetStateAction<string> } }) => {
    setCurrentText(event.target.value);
  };

  function saveRenamedFlow() {
    const newName = currentText.trim();
    if (!newName.length) {
      setIsEditFlowName(false);
      setFocusOnInput(false);
      setCurrentText(!lastText.length ? props.flowItem.title : lastText);
      return;
    }
    dispatch(renameFlow({ flowId: props.flowItem.id, newName: currentText }));
    setIsEditFlowName(false);
    setFocusOnInput(false);
    setCurrentText(currentText.trim());
    setLastText(!currentText.length ? props.flowItem.title : currentText);
    trackEvent("FlowNameChange", "", { target: "ListScreen" });
  }

  function checkIsUnlimited() {
    if (props.IsUnlimited) {
      return (
        <Text variant="medium" marginLeft="8px" noOfLines={1}>
          {`${props.flowItem.nodesCount}`}
        </Text>
      );
    } else {
      return (
        <Text variant="medium" marginLeft="8px" noOfLines={1}>
          {`${props.flowItem.nodesCount}/${props.accessibilityNodeCount["MaxCount"]}`}
        </Text>
      );
    }
  }

  function exportFlow() {
    dispatch(getExportFlow({ flowId: props.flowItem.id, title: props.flowItem.title }));
  }

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const textRefDesk = useRef<HTMLParagraphElement>(null);
  const textRefMob = useRef<HTMLParagraphElement>(null);

  const toggleTooltipVisible = () => {
    const element = textRefMob.current ? textRefMob.current : textRefDesk.current;
    if (element) {
      if (element.offsetWidth < element.scrollWidth) {
        setIsTooltipVisible(true);
      } else {
        setIsTooltipVisible(false);
      }
    }
  };

  return (
    <Box
      ref={setNodeRef}
      style={style}
      className={s.flowItemSortableWrapper}
      onMouseEnter={() => {
        setFocusOnCard(true);
        toggleTooltipVisible();
      }}
      onMouseLeave={() => setFocusOnCard(false)}
    >
      {props.isDesktop && props.showReorder && (
        <Box {...attributes} {...listeners} className={s.dragArea} data-pw="duble-arrow">
          {(focusOnCard || props.isDrag) && !props.hideArrow && (
            <DoubleArrowIcon className={`${s.dragIcon} ${props?.isDrag ? s.active : ""}`} />
          )}
        </Box>
      )}
      <Card
        variant="dominoListTile"
        key={props.flowItem.id}
        onClick={() => {
          if (!isEditFlowName && !focusOnInput) {
            trackEvent("FlowChange", "", { target: "Main_screen", flow_event: "Edit" });
            props.selectFlow(props.flowItem);
          }
        }}
        onTouchStart={() => {
          if (!isEditFlowName && !focusOnInput) {
            trackEvent("FlowChange", "", { target: "Main_screen", flow_event: "Edit" });
            props.selectFlow(props.flowItem);
          }
        }}
        className={props.isDrag ? s.dragCard : ""}
        width={"100%"}
        mb={"10px"}
      >
        <CardBody className={s.flowListItem_CardBody_Desktop} data-pw="flow-card">
          <div key={props.flowItem.id} className={`${s.flowListItemGrid}`}>
            {!isEditFlowName ? (
              <Box
                display={"flex"}
                gap={"4px"}
                maxW="100%"
                className={s.flowNameInput2}
                onMouseEnter={() => setFocusOnInput(true)}
                onMouseLeave={() => setFocusOnInput(false)}
              >
                <Tooltip
                  label={currentText}
                  placement="top"
                  isDisabled={!isTooltipVisible}
                  variant={"dominoChat"}
                  bg={"#FEF6DC"}
                  hasArrow={true}
                  openDelay={100}
                >
                  <Text
                    ref={textRefDesk}
                    style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                    data-pw="flow-name"
                    onClick={() => setIsEditFlowName(true)}
                    width={"max-content"}
                  >
                    {currentText}
                  </Text>
                </Tooltip>

                <Box marginTop={"3px"} hidden={!focusOnCard} data-pw="edit-flow-name">
                  <EditIcon onClick={() => setIsEditFlowName(true)} fill={!focusOnInput ? "#8592A3" : "#4F3DED"} />
                </Box>
              </Box>
            ) : (
              <Box w="100%" display={"flex-inline"} gap={"6px"}>
                <Input
                  data-pw="flow-name-input"
                  className={s.flowNameInput}
                  autoFocus={true}
                  value={currentText}
                  bg="white"
                  placeholder={`Enter the new Flow name`}
                  onChange={onChange}
                  onBlur={() => {
                    saveRenamedFlow();
                  }}
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      saveRenamedFlow();
                    }
                  }}
                  width="100%"
                />
              </Box>
            )}
            {checkIsUnlimited()}
            <Text variant="medium" marginLeft="8px" noOfLines={1} data-pw="run-count">
              {props.flowItem.runCount}
            </Text>
            <FormattedDateTooltip lng={props.lng} lastModifiedDate={props.flowItem.lastModifiedDate} />
            <FlowListItemMenu
              flowItem={props.flowItem}
              canAddFlow={props.canAddFlow}
              IsExportAvailable={props.IsExportAvailable}
              onDelete={props.onDelete}
              onCopy={props.onCopy}
              exportFlow={exportFlow}
            />
          </div>
        </CardBody>
        {/* MOBILE */}
        <CardBody className={`${s.flowListItem_CardBody_Mobile}`}>
          <div className={`${s.flowListItemGridTitleMobile}`}>
            <Tooltip
              label={currentText}
              placement="top"
              isDisabled={!isTooltipVisible}
              variant={"dominoChat"}
              bg={"#FEF6DC"}
              hasArrow={true}
              openDelay={100}
            >
              <Text
                ref={textRefMob}
                className={`${s.flowListItemGridTitleMobile_text}`}
                style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
              >
                {currentText}
              </Text>
            </Tooltip>
            <FlowListItemMenu
              flowItem={props.flowItem}
              canAddFlow={props.canAddFlow}
              IsExportAvailable={props.IsExportAvailable}
              onDelete={props.onDelete}
              onCopy={props.onCopy}
              exportFlow={exportFlow}
            />
          </div>
          <div className={`${s.flowListItemGridMobile}`}>
            <Box className={`${s.flowListFilterMobile}`}>
              <Text className={`${s.flowListFilterMobile_text}`} justifySelf="center">
                {tp("Nodes")}
              </Text>
              <Text className={`${s.flowListFilterMobile_text}`} justifySelf="center" noOfLines={1}>
                {t("Runs")}
              </Text>
              <Text className={`${s.flowListFilterMobile_text}`} justifySelf="center" noOfLines={1}>
                {t("Modified")}
              </Text>
            </Box>
            <Box w="max-content" key={props.flowItem.id}>
              <Text className={`${s.flowListFilterMobile_text}`} justifySelf="center" mr="36px" noOfLines={1}>
                {props.IsUnlimited
                  ? `${props.flowItem.nodesCount}`
                  : `${props.flowItem.nodesCount}/${props.accessibilityNodeCount["MaxCount"]}`}
              </Text>
              <Text className={`${s.flowListFilterMobile_text}`} justifySelf="center" noOfLines={1}>
                {props.flowItem.runCount}
              </Text>
              <Text className={`${s.flowListFilterMobile_text}`} justifySelf="center" noOfLines={1}>
                {fromNow(props.flowItem.lastModifiedDate, props.lng)}
              </Text>
            </Box>
            {!props.isDesktop && props.showReorder && (
              <Box {...attributes} {...listeners} className={`${s.dragArea} ${s.mobile}`}>
                <DoubleArrowIcon className={s.dragIcon} />
              </Box>
            )}
          </div>
        </CardBody>
        {/* MOBILE */}
      </Card>
    </Box>
  );
};
