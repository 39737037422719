import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../common/state/store";
import {
  getFlowList,
  selectBotVariablesInFlow,
  selectCanAddNewFlow,
  selectCanImportNewFlow,
  selectFlowList,
  selectImportResultsFlow,
} from "../AutomationSlice";
import {
  Box,
  Button,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { DeleteFlowPopup } from "./DeleteFlowPopup/DeleteFlowPopup";
import { getBots, selectBots, selectCurrentBot, selectIsCurrentBotLoading } from "../../sidebar/SidebarSlice";
import { FlowList } from "./FlowList";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { selectLanguage } from "../../../common/user/UserSlice";
import { EmptyBotView } from "../../emptyView/EmptyBotView";
import { EmptyListView } from "../../emptyView/EmptyListView";
import s from "../Automation.module.scss";
import sp from "../../../common/tariffPlan/TariffPlanStarPlan.module.scss";
import { useGetLoadingState } from "../../../common/loading/hooks/useGetLoadingState";
import { CopyFlowPopup } from "./CopyFlowPopup/CopyFlowPopup";
import { FlowTemplatesModal } from "./FlowTemplatesModal/FlowTemplatesModal";
import * as vars from "../../layout/LayoutHelper/ResolutionConst";
import { ImportFlowModal } from "./ImportFlowModal/ImportFlowModal";
import { PaginatorWithSearchParams } from "../../../common/paginator/components/PaginatorWithSearchParams";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plusIconTile.svg";
import { ReactComponent as ImportIcon } from "../../../assets/icons/download.svg";
import { ReactComponent as StarPlan } from "../../../assets/icons/starPlan.svg";

import { useGAWithCustomParameters } from "../../../common/ga/GAEventTracker";
import { TariffPlanRedirectButton } from "../../../common/tariffPlan/TariffPlanRedirectButton";

export const FlowListContainer = () => {
  const { t } = useTranslation("translation", { keyPrefix: "flowList" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const tp = useTranslation("translation", { keyPrefix: "tariffPlan" }).t;

  const dispatch = useAppDispatch();

  const lng = useAppSelector(selectLanguage);
  const botList = useAppSelector(selectBots);
  const currentBot = useAppSelector(selectCurrentBot);
  const isCurrentBotLoading = useAppSelector(selectIsCurrentBotLoading);
  const flowList = useAppSelector(selectFlowList);
  const botVariablesInFlow = useAppSelector(selectBotVariablesInFlow);
  const importResultsFlow = useAppSelector(selectImportResultsFlow);
  const loadingFlowList = useGetLoadingState("getFlowList");
  const trackEvent = useGAWithCustomParameters("Flow");

  const { isOpen: isOpenTemplates, onOpen: onOpenTemplates, onClose: onCloseTemplates } = useDisclosure();
  const { isOpen: isOpenImportFlow, onOpen: onOpenImportFlow, onClose: onCloseImportFlow } = useDisclosure();

  const [deletePopupState, setDeletePopupState] = useState<{
    id: string;
    title: string;
  } | null>(null);
  const [copyPopupState, setCopyPopupState] = useState<{
    id: string;
    title: string;
  } | null>(null);

  const [searchParams] = useSearchParams("");
  const currentPage = Number(searchParams.get("page") ?? 1);
  const search = searchParams.get("search") ?? "";
  const canAddFlow = useAppSelector(selectCanAddNewFlow) ?? false;
  const isImportAvailable = useAppSelector(selectCanImportNewFlow) ?? false;

  useEffect(() => {
    if (currentBot) {
      dispatch(getFlowList({ botId: currentBot.id, page: currentPage, search }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBot, searchParams, importResultsFlow]);

  const getAdditionalBots = () => {
    if (botList) {
      dispatch(getBots({ page: botList?.currentPage + 1 }));
    }
  };

  useEffect(() => {
    if (flowList) {
      trackEvent("FlowListLoaded", "", { count: flowList.totalItems });
    }
  }, [flowList]);

  if (!currentBot && !isCurrentBotLoading) {
    return <EmptyBotView title={t("No flows")} description={t("Create or connect an existing bot to add your first Flow.")} />;
  }

  if (flowList?.items.length === 0 && search !== "") {
    return (
      <Box className={s.noData} position={"relative"}>
        <Box className={`${s.flowListItemGrid} ${s.flowListFilter}`}>
          <Text noOfLines={1} ml="16px">
            {t("Name")}
          </Text>
          <Text justifySelf="center" mr="36px" noOfLines={1}>
            {t("Runs")}
          </Text>
          <Text justifySelf="center" noOfLines={1}>
            {t("Modified")}
          </Text>
          <div />
        </Box>
        <Box className={s.noSearchResults}>{ct("No results found")}</Box>
      </Box>
    );
  }

  if (flowList?.items.length === 0 && search === "" && !loadingFlowList) {
    return (
      <>
        <EmptyListView
          title={t("Create your first Flow")}
          description={t("Automate communication with your contacts by creation of chatbot Flows.")}
          CustomButton={
            <Flex
              gap={{ base: "16px", xl: "24px" }}
              flexDirection={["column", "column", "column", "column", "row"]}
              width={"100%"}
            >
              {!canAddFlow ? (
                <Popover trigger={"hover"}>
                  {({ onClose }) => (
                    <>
                      <PopoverTrigger>
                        <Button
                          size="sm"
                          variant="dominoViolet"
                          px="24px"
                          className={sp.starPlanButton}
                          data-pw="new-flow-button"
                        >
                          <Icon className={sp.starPlan} boxSize="20px" as={StarPlan} />
                          {t("New Flow")}
                        </Button>
                      </PopoverTrigger>
                      <Portal>
                        <PopoverContent background="#FEF6DC" borderColor="#FEF6DC" zIndex={1} maxW={250}>
                          <PopoverArrow bg="#FEF6DC" borderColor="#FEF6DC" />
                          <Flex alignItems="center" justifyContent="center" direction="column">
                            <PopoverHeader borderBottom="none" fontSize={16} mb={-3} fontWeight={600}>
                              {tp("Advanced feature")}
                            </PopoverHeader>
                            <PopoverBody textAlign="center" maxW={200} fontSize={13}>
                              {tp("Please upgrade your plan to create more Flows")}
                            </PopoverBody>
                            <TariffPlanRedirectButton onClose={onClose} />
                          </Flex>
                        </PopoverContent>
                      </Portal>
                    </>
                  )}
                </Popover>
              ) : (
                <Button data-pw="new-flow-button" variant="dominoViolet" onClick={() => onOpenTemplates()}>
                  <Flex gap={"8px"} align={"center"}>
                    <PlusIcon />
                    {t("New Flow")}
                  </Flex>
                </Button>
              )}
              {!isImportAvailable || !canAddFlow ? (
                <Popover trigger={"hover"}>
                  {({ onClose }) => (
                    <>
                      <PopoverTrigger>
                        <Button
                          size="sm"
                          variant="dominoOutlineViolet"
                          data-pw="import-flow-button"
                          className={sp.starPlanButton}
                        >
                          <Icon className={sp.starPlan} boxSize="20px" fill={"#6D5BF7"} as={StarPlan} />
                          {t("Import Flow")}
                        </Button>
                      </PopoverTrigger>
                      <Portal>
                        <PopoverContent background="#FEF6DC" borderColor="#FEF6DC" zIndex={1} maxW={250}>
                          <PopoverArrow bg="#FEF6DC" borderColor="#FEF6DC" />
                          <Flex alignItems="center" justifyContent="center" direction="column">
                            <PopoverHeader borderBottom="none" fontSize={16} mb={-3} fontWeight={600}>
                              {tp("Advanced feature")}
                            </PopoverHeader>
                            <PopoverBody textAlign="center" maxW={200} fontSize={13}>
                              {tp("Please upgrade your plan to import Flows")}
                            </PopoverBody>
                            <TariffPlanRedirectButton onClose={onClose} />
                          </Flex>
                        </PopoverContent>
                      </Portal>
                    </>
                  )}
                </Popover>
              ) : (
                <Button size="sm" variant="dominoOutlineViolet" onClick={() => onOpenImportFlow()} data-pw="import-flow-button">
                  <Flex gap={"8px"} align={"center"}>
                    <ImportIcon />
                    <Text>{t("Import Flow")}</Text>
                  </Flex>
                </Button>
              )}
            </Flex>
          }
        />
        <FlowTemplatesModal isOpen={isOpenTemplates} onClose={onCloseTemplates} />
        <ImportFlowModal isOpen={isOpenImportFlow} onClose={onCloseImportFlow} />
      </>
    );
  }

  return (
    <>
      {deletePopupState && <DeleteFlowPopup deletePopupState={deletePopupState} onClose={() => setDeletePopupState(null)} />}
      {copyPopupState && (
        <CopyFlowPopup
          botVariablesInFlow={botVariablesInFlow?.botVariables}
          copyPopupState={copyPopupState}
          botList={botList}
          onClose={() => setCopyPopupState(null)}
          getAdditionalBots={getAdditionalBots}
        />
      )}
      <Box padding={`0 16px 32px 16px`} pb={"12px"} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
        {flowList && currentBot && (
          <FlowList
            onCopy={flow => setCopyPopupState({ id: flow.id, title: flow.title })}
            lng={lng}
            flowItems={flowList.items}
            botId={currentBot.id}
            currentPage={currentPage}
            onDelete={flow => setDeletePopupState({ id: flow.id, title: flow.title })}
          />
        )}
        {flowList && window.innerWidth >= vars.TABLET_VIEW ? (
          <PaginatorWithSearchParams data={flowList} typeOfPage={"FlowList"} paddingLeft="24px" />
        ) : flowList && window.innerWidth < vars.TABLET_VIEW ? (
          <PaginatorWithSearchParams data={flowList} typeOfPage={"FlowList"} paddingLeft="3px" />
        ) : null}
      </Box>
    </>
  );
};
