import i18next from "i18next";
import { AppSettings } from "../../common/AppSettings";
import { BadRequestError, Error400Model } from "../../common/ErrorModel";
import { GetTokenPasswordRequestModel, GetTokenRequestBaseModel } from "./LoginModel";

declare const appSettings: AppSettings;

export const getToken = async (data: GetTokenRequestBaseModel) => {
  let organizationId: string | undefined;

  const passwordModel = data as GetTokenPasswordRequestModel;
  if (passwordModel) {
    organizationId = JSON.parse(sessionStorage.getItem("usersOrganizationId") || "{}")[passwordModel.identity];
  }

  const headers: {
    Accept: string;
    "Content-Type": string;
    Pragma: string;
    "Cache-Control": string;
    "x-domino-lang": string;
    "x-domino-organization"?: string;
  } = {
    Accept: "application/vnd.domino.v1+json",
    "Content-Type": "application/vnd.domino.v1+json",
    Pragma: "no-cache",
    "Cache-Control": "no-cache",
    "x-domino-lang": i18next.language,
  };

  if (organizationId) {
    headers["x-domino-organization"] = organizationId;
  }

  const res = await fetch(`${appSettings.apiIdentityProvider}/login`, {
    method: "POST",
    headers: headers,
    credentials: "include",
    body: data ? JSON.stringify(data) : undefined,
  });

  if (res?.status === 401) {
    const error = { message: "Invalid identity or password" };
    throw new BadRequestError(error.message);
  }
  if (res?.status === 400) {
    const data: Error400Model = await res.json();
    throw new BadRequestError(data?.uiMessage ?? data.message);
  }
  if (res.status === 200) {
    return await res.json();
  }
};

export const getTokenByAuthCode = async (data: GetTokenRequestBaseModel) => {
  const res = await fetch(`${appSettings.apiIdentityProvider}/auth/code`, {
    method: "POST",
    headers: {
      Accept: "application/vnd.domino.v1+json",
      "Content-Type": "application/vnd.domino.v1+json",
      Pragma: "no-cache",
      "Cache-Control": "no-cache",
      "x-domino-lang": i18next.language,
    },
    credentials: "include",
    body: data ? JSON.stringify(data) : undefined,
  });
  if (res?.status === 401) {
    const error = { message: "Invalid auth code" };
    throw new BadRequestError(error.message);
  }
  if (res?.status === 400) {
    const data: Error400Model = await res.json();
    throw new BadRequestError(data?.uiMessage ?? data.message);
  }
  if (res.status === 200) {
    return await res.json();
  }
};
