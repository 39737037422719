import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  Box,
  List,
  ListItem,
  Flex,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import s from "./ModalTrial.module.scss";
import { getCookieString } from "../../../../../common/auth/AuthRepo";
import { TrialPlanModel } from "../TrialPlanModel";
import { activateTrialPlan } from "../../../OrganisationSlice";
import { useAppDispatch } from "../../../../../common/state/store";
import { convertDaysLocalization } from "../../../../../common/utils/formatDate";
import { memo } from "react";
import { TariffPlanFeatureTypeEnum } from "../../../../../common/AppEnums";
import { getTariffPlanAccessability } from "../../../../../common/tariffPlan/TariffPlanUtil";
interface Props {
  isOpenModal: boolean;
  setIsOpenModal(isOpen: boolean): void;
  trialPlan: TrialPlanModel;
}

export const TrialModal = memo(function TrialModal(props: Props) {
  const { t } = useTranslation("translation", { keyPrefix: "TrialPlan" });
  const dispatch = useAppDispatch();
  const organizationId = getCookieString("organizationId") ?? "";
  const duration = convertDaysLocalization(props.trialPlan.trialDays);
  const bot: { MaxCount: number } = getTariffPlanAccessability(TariffPlanFeatureTypeEnum.BotCount);
  const conversation: { MaxCount: number } = getTariffPlanAccessability(TariffPlanFeatureTypeEnum.ConversationCount);
  const operators: { MaxCount: number } = getTariffPlanAccessability(TariffPlanFeatureTypeEnum.OrganisationMembersCount);

  const handleActivateButton = () => {
    dispatch(activateTrialPlan(organizationId));
    onClose();
  };
  const onClose = () => {
    props.setIsOpenModal(false);
  };

  return (
    <>
      <Modal isOpen={props.isOpenModal} onClose={onClose}>
        <ModalOverlay />
        <ModalContent className={s.Modal}>
          <ModalCloseButton />
          <ModalBody padding={0} width={"100%"}>
            <Text className={s.Title}>{t("The benefit that is obvious")}</Text>
            <Text className={s.TrialInfo}>
              {t(`Your current tariff is Free, try all the features of the`)}
              <Text margin={0} padding={0} fontWeight={"700"}>
                {t("trialPlanName")} {duration} {t("tariff for free")}
              </Text>
            </Text>
            <Box className={s.TrialFeaturesWrapper}>
              <Text fontSize={"15px"} fontWeight={"bold"}>
                {t("Pro tariff limits")}:
              </Text>
              <List className={s.TrialFeaturesList}>
                {props.trialPlan.botsCount && (
                  <ListItem>
                    <Flex gap="4px" alignItems="center">
                      <Text textDecoration="line-through">{bot.MaxCount}</Text>
                      <Text>/</Text>
                      <Text as="span" fontSize="15px">
                        {props.trialPlan.botsCount}
                      </Text>
                    </Flex>
                    <Text as="span" fontSize="13px">
                      {t("Chatbots")}
                    </Text>
                  </ListItem>
                )}
                {props.trialPlan.conversationCount && (
                  <ListItem>
                    <Flex gap="4px" alignItems="center">
                      <Text textDecoration="line-through">{conversation.MaxCount}</Text>
                      <Text>/</Text>
                      <Text as="span" fontSize="15px">
                        {props.trialPlan.conversationCount}
                      </Text>
                    </Flex>
                    <Text as="span" fontSize="13px">
                      {t("Conversations")}
                    </Text>
                  </ListItem>
                )}
                {props.trialPlan.conversationCount && (
                  <ListItem>
                    <Flex gap="4px" alignItems="center">
                      <Text textDecoration="line-through">{operators.MaxCount}</Text>
                      <Text>/</Text>
                      <Text as="span" fontSize="15px">
                        {props.trialPlan.operatorsCount}
                      </Text>
                    </Flex>
                    <Text as="span" fontSize="13px">
                      {t("Operators")}
                    </Text>
                  </ListItem>
                )}
              </List>
            </Box>
          </ModalBody>
          <ModalFooter width={"100%"} padding={"0"}>
            <Button onClick={handleActivateButton} className={s.ActivateButton}>{`${t("Activate the")} ${t(
              "trialPlanName",
            )} ${duration} ${t("for free")}`}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
});
