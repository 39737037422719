import { Button, Container, Flex, FormControl } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import {
  createNewBroadcast,
  deleteBroadcast,
  editBroadcast,
  selectBroadcastIsInitialDisabledButtons,
  selectBroadcastIsNew,
  //selectBroadcastSchedulerValidation,
  selectBroadcastState,
  selectBroadcastValidation,
  selectShowBoradcastLeave,
  //selectBroadcastTitleValidation,
  //setBroadcastExecute,
  //setBroadcastIsExecute,
} from "../../../../BroadcastSlice";
import { useGAWithCustomParameters } from "../../../../../../common/ga/GAEventTracker";
import s from "../../Broadcast.module.scss";
import { useAppDispatch, useAppSelector } from "../../../../../../common/state/store";
import { selectCurrentBot } from "../../../../../sidebar/SidebarSlice";
import { selectImportRecipientList, selectRecipientCount, selectTotalContacts } from "../../../ContactList/RecipientListSlice";
import { DeleteBroadcastPopup } from "../../../DeleteBroadcastPopup/DeleteBroadcastPopup";
import { useState } from "react";
import { LeaveConfirmationPopup } from "../../../LeaveConfirmationPopup/LeaveConfirmationPopup";

interface CommonProps {
  messageInvalid: boolean;
  dirty: boolean;
}

// interface EditProps extends CommonProps {
//   onBroadcastDeleteClick: (id: string, itemName: string) => void;
//   broadcastState: BroadcastState;
// }

// interface NewProps extends CommonProps {
//   onSaveDraft: () => void;
//   onExecute: () => void;
// }

//type Props = EditProps | NewProps;

export const BroadcastFormButtons = (props: CommonProps) => {
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;
  const { t } = useTranslation("translation", { keyPrefix: "broadcast" });
  const trackEvent = useGAWithCustomParameters("Broadcast");
  const dispatch = useAppDispatch();
  // const isValidBroadcastTitle = useAppSelector(selectBroadcastTitleValidation);
  const broadcast = useAppSelector(selectBroadcastState).broadcast;
  const isBroadcastNew = useAppSelector(selectBroadcastIsNew);
  const currentBotId = useAppSelector(selectCurrentBot);
  const { isError } = useAppSelector(selectBroadcastValidation);
  const recipientsCount = useAppSelector(selectRecipientCount);
  const totalItemsCount = useAppSelector(selectTotalContacts);
  const isInitialDisabledButtons = useAppSelector(selectBroadcastIsInitialDisabledButtons);
  const importRecipientsList = useAppSelector(selectImportRecipientList);
  const showLeaveBroadcastPopup = useAppSelector(selectShowBoradcastLeave);

  const [deletePopupState, setDeletePopupState] = useState<{ id: string; itemName: string } | null>(null);
  const onDeletePopupClose = () => setDeletePopupState(null);
  const deleteBroadcastDispatch = (broadcastId: string) => {
    setIsOnClickButton(true);
    dispatch(deleteBroadcast({ broadcastId }));
  };
  const [isOnClickButton, setIsOnClickButton] = useState(false);

  // const isValidSheduler = useAppSelector(selectBroadcastSchedulerValidation);

  // const onSave = () => {
  //   //values.sentForAll = wasSelectAllChecked;
  //   const commonPayload = {
  //     botId: currentBotId ?? "",
  //     broadcast: broadcast,
  //     execute: isExecute,
  //     //recipientFilter: mapBroadcastFiltersToData(broadcastFilter),
  //   };
  //   if (isNew) {
  //     dispatch(
  //       createBroadcast({
  //         ...commonPayload,
  //         sentForAll: recipientList?.availableRecipients ? false : wasSelectAllChecked,
  //       }),
  //     );
  //   } else {
  //     if (!executeState.execute) {
  //       dispatch(
  //         editBroadcast({
  //           ...commonPayload,
  //           broadcast: {
  //             ...values,
  //             sentForAll: recipientList?.availableRecipients ? false : wasSelectAllChecked,
  //           },
  //           execute: false,
  //         }),
  //       );
  //     } else if (executeState.dirty) {
  //       dispatch(
  //         editBroadcast({
  //           ...commonPayload,
  //           broadcast: {
  //             ...values,
  //             sentForAll: recipientList?.availableRecipients ? false : wasSelectAllChecked,
  //           },
  //           execute: true,
  //         }),
  //       );
  //     } else {
  //       dispatch(
  //         executeBroadcast({
  //           botId: currentBotId ?? "",
  //           broadcastId: broadcastState.broadcast?.id ?? "",
  //         }),
  //       );
  //     }
  //   }
  // };

  // const commonValidation = broadcast?.isScheduled
  //   ? !isValidBroadcastTitle && !props.messageInvalid && broadcast?.scheduledDate && !isValidSheduler
  //   : !isValidBroadcastTitle && !props.messageInvalid;

  // const validateExecution = () => {
  //   if (props.wasSelectAllChecked) {
  //     return !(commonValidation && props.wasSelectAllChecked);
  //   } else {
  //     return !(commonValidation && broadcast?.recipients?.length !== 0);
  //   }
  // };

  const isDisabledButton =
    importRecipientsList?.recipients === null
      ? isInitialDisabledButtons || recipientsCount <= 0 || isError
      : isInitialDisabledButtons || Number(importRecipientsList?.availableRecipients) <= 0 || isError;

  const isDisabledButtonStartDraft =
    importRecipientsList?.recipients === null
      ? recipientsCount <= 0 || isError
      : Number(importRecipientsList?.availableRecipients) <= 0 || isError;

  const renderButtons = () => {
    if (isBroadcastNew) {
      return (
        <>
          <Button
            size="sm"
            variant="dominoOutlineViolet"
            type="submit"
            isDisabled={isDisabledButton}
            onClick={() => {
              trackEvent("Broadcast", "", {
                broadcast_event: "Save",
                total_contacts_count: totalItemsCount,
                select_contacts_count: recipientsCount,
                count: broadcast?.message.buttons.length,
              });
              dispatch(createNewBroadcast({ execute: false, botId: currentBotId?.id ?? "" }));
              setIsOnClickButton(true);
            }}
            data-pw="broadcast-save-draft-button"
          >
            {t("Save draft")}
          </Button>
          <Button
            data-pw="broadcast-start-button"
            ml="16px"
            size="sm"
            variant="dominoViolet"
            type="submit"
            isDisabled={isDisabledButton}
            onClick={() => {
              trackEvent("Broadcast", "", { broadcast_event: "Start" });
              dispatch(createNewBroadcast({ execute: true, botId: currentBotId?.id ?? "" }));
              setIsOnClickButton(true);
            }}
          >
            {broadcast?.isScheduled ? t("Schedule broadcast") : t("Start broadcast")}
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button
            isDisabled={isDisabledButton}
            size="sm"
            variant="dominoOutlineViolet"
            type="submit"
            onClick={() => {
              trackEvent("Broadcast", "", {
                broadcast_event: "Save",
                total_contacts_count: totalItemsCount,
                select_contacts_count: recipientsCount,
                count: broadcast?.message.buttons.length,
              });
              dispatch(
                editBroadcast({
                  execute: false,
                  botId: broadcast?.botId ?? "",
                }),
              );
              setIsOnClickButton(true);
            }}
            data-pw="broadcast-save-button"
          >
            {ct("Save")}
          </Button>
          <Button
            data-pw="broadcast-start-button"
            isDisabled={isDisabledButtonStartDraft}
            size="sm"
            ml="16px"
            variant="dominoViolet"
            type="submit"
            onClick={() => {
              trackEvent("Broadcast", "", {
                broadcast_event: "Start",
                total_contacts_count: totalItemsCount,
                select_contacts_count: recipientsCount,
                count: broadcast?.message.buttons.length,
              });
              setIsOnClickButton(true);
              dispatch(
                editBroadcast({
                  execute: true,
                  botId: broadcast?.botId ?? "",
                }),
              );
            }}
          >
            {broadcast?.isScheduled ? t("Schedule broadcast") : t("Start broadcast")}
          </Button>
          <Button
            size="sm"
            variant="dominoOutlineRed"
            ml="16px"
            onClick={e => {
              trackEvent("Broadcast", "", { broadcast_event: "Delete" });
              e.stopPropagation();
              setDeletePopupState({ id: broadcast.id, itemName: broadcast.name });
            }}
            data-pw="broadcast-remove-button"
          >
            {ct("Remove")}
          </Button>
        </>
      );
    }
  };

  return (
    <Container backgroundColor="none" variant="dominoContentBlock">
      <FormControl
        style={{ paddingTop: "0px" }}
        className={`${s.broadcastListItemGrid} ${s.broadcastListItemGridStart} ${s.broadcastAdaptiveButton}`}
      >
        <div />
        <Flex className={s.broadcastFlexButton}>{renderButtons()}</Flex>
      </FormControl>
      <DeleteBroadcastPopup
        deleteBroadcastState={deletePopupState}
        onDelete={deleteBroadcastDispatch}
        onClose={onDeletePopupClose}
      />
      <LeaveConfirmationPopup dirty={showLeaveBroadcastPopup && !isOnClickButton} />
    </Container>
  );
};
