import { useState } from "react";
import { useTranslation } from "react-i18next";
import Joyride, { EVENTS, Step } from "react-joyride";
import { useGAWithCustomParameters } from "../../common/ga/GAEventTracker";
import { completeTour, selectTours, setTourStep, skipTour } from "./OnboardingToursSlice";
import { useAppDispatch, useAppSelector } from "../../common/state/store";
import { Tour } from "./OnboardingToursState";
import { customStyles } from "./onboardingStyles";
import { FLOW_BUILDER_TOUR_NAME } from "./toursConstants";

interface Props {
  currentTourName: string;
  tourLevelNameForGAEvent: string;
  steps: Step[];
  tooltipWidth?: string;
}

export function OnboardingToursContainer({ currentTourName, tourLevelNameForGAEvent, steps, tooltipWidth }: Props) {
  const { t } = useTranslation("translation", { keyPrefix: "onboardingTour" });
  const dispatch = useAppDispatch();
  const tours = useAppSelector(selectTours);
  const trackEvent = useGAWithCustomParameters(tourLevelNameForGAEvent);
  const [currentStep, setCurrentStep] = useState(0);
  const currentTourState = tours?.find((t: Tour) => t.tourName === currentTourName);
  const isCurrentTourRun = !currentTourState ? false : currentTourState.run;
  const customOnboardingStyles = tooltipWidth ? customStyles(tooltipWidth) : customStyles();

  const lastButtonName = currentTourName === FLOW_BUILDER_TOUR_NAME ? t("try") : t("last");

  const trackGoogleEvents = (action: string, currentStep: number, tourLevelName: string) => {
    if (action === "next") {
      trackEvent(`${tourLevelName}Forward`);
    }
    if (action === "close") {
      trackEvent(`${tourLevelName}Close`, "", { step: Number(currentStep + 1) }); //
    }
    if (action === "prev") {
      trackEvent(`${tourLevelName}Back`);
    }
    if (action === "skip") {
      trackEvent(`${tourLevelName}Later`);
    }
    if (action === "finish") {
      trackEvent(`${tourLevelName}Finish`);
    }
  };

  const handleJoyrideCallback = (data: { status: string; type: string; index: number; action: string }) => {
    const { status, type, index, action } = data;
    trackGoogleEvents(action, currentStep, tourLevelNameForGAEvent);
    if (status === "skipped" || status === "finished" || action === "close") {
      dispatch(setTourStep({ tourName: currentTourName, step: currentStep }));
      dispatch(skipTour(currentTourName));
    }

    if (type === EVENTS.STEP_AFTER && index === steps.length - 1 && action !== "prev") {
      if (currentTourState?.isCompleted) return;
      dispatch(setTourStep({ tourName: currentTourName, step: currentStep }));
      dispatch(completeTour(currentTourName));
    }
    setCurrentStep(index);
  };

  return (
    <Joyride
      callback={handleJoyrideCallback}
      continuous
      run={isCurrentTourRun}
      steps={steps}
      scrollToFirstStep
      showSkipButton={currentStep === 0}
      locale={{ skip: t("skip"), next: t("next"), back: t("back"), last: lastButtonName }}
      disableOverlayClose={true}
      styles={customOnboardingStyles}
      disableScrollParentFix
    />
  );
}
