import { Box, Flex } from "@chakra-ui/react";
import { ProtectedHeaderContainer } from "../../../../headers/protectedHeaderContainer/ProtectedHeaderContainer";
import { Loading } from "../../../../../common/loading/LoadingStateContainer";
import { BroadcastListHeader } from "../../../../broadcastList/components/BroadcastListHeader/BroadcastListHeader";
import { BroadcastListContainer } from "../../../../broadcastList/BroadcastListContainer";
import s from "./BroadcastListPage.module.scss";

export const BroadcastListPage = () => {
  return (
    <Flex className={s.pageContainer}>
      <ProtectedHeaderContainer>
        <BroadcastListHeader />
      </ProtectedHeaderContainer>
      <Box className={s.pageContent}>
        <Loading />
        <BroadcastListContainer />
      </Box>
    </Flex>
  );
};
