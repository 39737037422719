import s from "../../../UserProfile.module.scss";
import { Box, Button, Flex, Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import { UserNotificationsProvider } from "../../../../../common/user/UserModel";
import { UserState } from "../../../../../common/user/UserState";
import { BotListModel } from "../../../../dashboard/DashboardModel";
import { useTranslation } from "react-i18next";
import { NotificationBotsModel, NotificationSettingsModel } from "../../../UserProfileModel";
import { ReactComponent as ArrowDropIcon } from "../../../../../assets/icons/arrowDrop.svg";
import { selectLinkedAccounts } from "../../../../../common/user/UserSlice";
import { useAppSelector } from "../../../../../common/state/store";
import { useEffect, useState } from "react";

interface Props {
  userProfile: UserState;
  bots: BotListModel | undefined;
  telegramBots: NotificationBotsModel[] | undefined;
  setBot: (notification?: NotificationSettingsModel) => void;
  setNewBot: (notification: NotificationSettingsModel) => void;
  disableBot: (disabledNotification: NotificationSettingsModel) => void;
  getPage: (page: number) => void;
  notification?: NotificationSettingsModel;
  organizationId: string;
}

export const UserProfilePushItem = (props: Props) => {
  const { t } = useTranslation("translation", { keyPrefix: "userProfile" });

  const linkedAccounts = useAppSelector(selectLinkedAccounts);
  const isTelegramAuth = linkedAccounts.length > 0;
  const getInitialNowBot = (notification?: NotificationSettingsModel) => {
    if (notification === undefined) {
      return { name: "None", type: UserNotificationsProvider.none };
    } else if (notification.provider === UserNotificationsProvider.none) {
      return { name: "None", type: UserNotificationsProvider.none };
    } else if (notification.provider === UserNotificationsProvider.dominoBot) {
      return {
        name: "Domino Bot",
        type: UserNotificationsProvider.dominoBot,
      };
    } else if (notification.provider === UserNotificationsProvider.customBot) {
      const findElem = props.telegramBots?.find(el => el.id === notification.customBotId);
      return findElem
        ? {
            name: findElem.name,
            type: UserNotificationsProvider.customBot,
          }
        : {
            name: "None",
            type: UserNotificationsProvider.none,
          };
    } else {
      return { name: "None", type: UserNotificationsProvider.none };
    }
  };

  function setBot(botId: string) {
    if (props.notification) {
      if (botId === "") {
        const notification: NotificationSettingsModel = {
          ...props.notification,
          provider: UserNotificationsProvider.dominoBot,
        };
        props.setBot(notification);
      } else if (botId === "none") {
        const notification: NotificationSettingsModel = {
          provider: UserNotificationsProvider.none,
          customBotId: botId,
          organizationId: props.organizationId,
        };
        props.disableBot(notification);
      } else {
        const notification: NotificationSettingsModel = {
          ...props.notification,
          provider: UserNotificationsProvider.customBot,
          customBotId: botId,
        };
        props.setBot(notification);
      }
    }
  }

  const [nowBot, setBotNow] = useState<{ name: string; type: UserNotificationsProvider } | null>(null);

  useEffect(() => {
    if (nowBot === null && props.notification !== undefined) {
      setBotNow(getInitialNowBot(props.notification));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.notification]);

  return (
    <>
      <Box>
        {props.notification && isTelegramAuth ? (
          <div className={s.contentItem}>
            <Menu
              matchWidth
              variant={{ base: "dominoProfileAdaptiveMenu", sm: "dominoProfileMenu" }}
              gutter={0}
              offset={[-30, 0]}
            >
              {({ isOpen, onClose }) => (
                <>
                  <MenuButton
                    variant="profileButton"
                    minW={{ base: "200px !important", sm: "250px !important" }}
                    isActive={isOpen}
                    as={Button}
                    onClick={e => e.stopPropagation()}
                    rightIcon={<ArrowDropIcon height="20px" width="20px" />}
                  >
                    <Flex maxW={"500px"}>
                      <Box w="100%" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                        {nowBot?.type === UserNotificationsProvider.none || nowBot?.type === UserNotificationsProvider.dominoBot
                          ? t(nowBot?.name)
                          : nowBot?.name}
                      </Box>
                    </Flex>
                  </MenuButton>
                  <MenuList overflow="auto !important" w={{ base: "200px", sm: "250px" }} maxH="250px" ml={"30px"}>
                    <MenuItem
                      onClick={e => {
                        setBotNow({ name: "None", type: UserNotificationsProvider.none });
                        setBot("none");
                      }}
                    >
                      {t("None")}
                    </MenuItem>
                    <MenuItem
                      onClick={e => {
                        setBotNow({
                          name: "Domino Bot",
                          type: UserNotificationsProvider.dominoBot,
                        });
                        setBot("");
                      }}
                    >
                      {t("Domino Bot")}
                    </MenuItem>
                    {props.telegramBots &&
                      props.telegramBots.length > 0 &&
                      props.telegramBots.map(bot => {
                        if (props.notification?.organizationId === bot.organizationId) {
                          return (
                            <MenuItem
                              onClick={e => {
                                setBotNow({
                                  name: bot.name,
                                  type: UserNotificationsProvider.customBot,
                                });
                                setBot(bot.id);
                              }}
                              key={bot.id}
                            >
                              {bot.name}
                            </MenuItem>
                          );
                        }
                        return null;
                      })}
                  </MenuList>
                </>
              )}
            </Menu>
          </div>
        ) : (
          <div className={s.contentItem}>
            <Menu
              matchWidth
              variant={{ base: "dominoProfileAdaptiveMenu", md: "dominoProfileMenu" }}
              gutter={0}
              offset={[-30, 0]}
            >
              {({ isOpen, onClose }) => (
                <>
                  <MenuButton
                    variant="profileButton"
                    minW={{ base: "200px !important", sm: "250px !important" }}
                    isActive={isOpen}
                    as={Button}
                    onClick={e => e.stopPropagation()}
                    rightIcon={<ArrowDropIcon />}
                  >
                    <Flex maxW={"500px"}>
                      <Box w="100%" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
                        {t("None")}
                      </Box>
                    </Flex>
                  </MenuButton>
                  <MenuList overflow="auto !important" w={{ base: "200px", sm: "250px" }} maxH="250px" ml={"30px"}>
                    <MenuItem>{t("None")}</MenuItem>
                  </MenuList>
                </>
              )}
            </Menu>
          </div>
        )}
      </Box>

      {/* <Box>
        {props.telegramBots && props.telegramBots.length > 0 ? (
          <div className={s.contentItem}>
            <MenuList className={s.botSelectInput}>
              <MenuButton className={s.menuButton} onClick={e => e.stopPropagation()}>
                <Flex justifyContent={"space-between"} alignItems={"center"}>
                  <Flex w={"90%"} alignItems={"center"} ml={"1%"}>
                    {"ff"}
                  </Flex>
                </Flex>
              </MenuButton>
              <MenuItem
                className={s.dizabledOption}
                value="none"
                onClick={() => {
                  handleMenuChange("none");
                }}
                //selected={props.notification ? checkIsBotsSelected(props.notification, UserNotificationsProvider.none) : true}
              >
                {t("None")}
              </MenuItem>
              <option
                value=""
                selected={
                  props.notification ? checkIsBotsSelected(props.notification, UserNotificationsProvider.dominoBot) : false
                }
              >
                {t("Domino Bot")}
              </option>
              {props.telegramBots.length
                ? // eslint-disable-next-line array-callback-return
                  props.telegramBots.map(bot => {
                    if (props.notification?.organizationId === bot.organizationId) {
                      return (
                        <option
                          value={bot.id}
                          key={bot.id}
                          selected={
                            props.notification
                              ? checkIsBotsSelected(props.notification, UserNotificationsProvider.customBot, bot.id)
                              : false
                          }
                        >
                          {bot.name}
                        </option>
                      );
                    }
                  })
                : t("(No bots")}
            </MenuList>
          </div>
        ) : (
          <div>
            <Select onChange={handleSelectChange} variant="unstyled" className={s.botSelectInput} icon={<ArrowDropIcon />}>
              <option
                className={s.dizabledOption}
                value="none"
                selected={props.notification ? checkIsBotsSelected(props.notification, UserNotificationsProvider.none) : true}
              >
                {t("None")}
              </option>
            </Select>
          </div>
        )}
      </Box> */}
    </>
  );
};
