import { Box, Button, Flex, IconButton, Text } from "@chakra-ui/react";
import s from "./Trial.module.scss";
import { ReactComponent as RollStar } from "../../../../assets/icons/RollStar.svg";
import { memo, useEffect, useState } from "react";
import { TrialModal } from "./TrilalModal/TrialModal";
import { TrialPlanModel } from "./TrialPlanModel";
import { useTranslation } from "react-i18next";
import { convertDaysLocalization } from "../../../../common/utils/formatDate";
import { useTabletView } from "../../../layout/LayoutHelper/ResolutionHooks";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import { selectIsInitialTrialLoading, setIsInitialTrialLoading } from "../../OrganisationSlice";

interface Props {
  trialPlan: TrialPlanModel;
}

export const TrialPlanComponent = memo(function TrialPlanComponent(props: Props) {
  const { t } = useTranslation("translation", { keyPrefix: "TrialPlan" });
  const duration = convertDaysLocalization(props.trialPlan.trialDays);
  const isTablet = useTabletView();
  const isInitialLoading = useAppSelector(selectIsInitialTrialLoading);
  const dispatch = useAppDispatch();
  const [isAnimated, setIsAnimated] = useState(false);

  useEffect(() => {
    if (!isInitialLoading) {
      setIsAnimated(true);
      dispatch(setIsInitialTrialLoading(true));
    }
  }, [isInitialLoading, dispatch]);

  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleOpenModal = () => {
    setIsOpenModal(true);
  };

  return (
    <>
      <Flex key="trial_Wrapper" className={isAnimated ? `${s.TrialWrapper} ${s.TrialWrapper_Animated}` : `${s.TrialWrapper}`}>
        <Flex className={s.TrialLogoStarWrapper}>
          {isTablet ? (
            <Button
              className={isAnimated ? `${s.TrialLogoCircle}` : `${s.TrialLogoCircle_noAnimated}`}
              leftIcon={<RollStar />}
              onClick={handleOpenModal}
            >
              <Text className={s.MobileTextButton}>{t("Try it free")}</Text>
            </Button>
          ) : (
            <IconButton className={s.TrialLogoCircle} backgroundColor={"none"} icon={<RollStar />} aria-label="trial" />
          )}
        </Flex>
        <Flex className={s.TrialTextWrapper}>
          <Box className={s.TrialTextInfoWrp}>
            <Text noOfLines={1} className={isAnimated ? s.TrialTitle : s.TrialTitle__noAnimated}>{`${t("trialPlanName")} ${t(
              "tariff",
            )} ${duration}`}</Text>
            <Text noOfLines={1} className={isAnimated ? `${s.TrialInfoLabel} ${s.TrialInfoAnimated}` : s.TrialInfoLabel}>
              {t("Connect trial free")}
            </Text>
          </Box>
          {!isTablet && (
            <Button className={s.openModalButton} onClick={handleOpenModal}>
              {t("Try it free")}
            </Button>
          )}
        </Flex>
      </Flex>

      <TrialModal trialPlan={props.trialPlan} isOpenModal={isOpenModal} setIsOpenModal={setIsOpenModal} />
    </>
  );
});
