import { call, put, select, takeLatest } from "redux-saga/effects";
import { beginScope, completeScope } from "../../../../common/loading/LoadingStateActions";
import { handleException } from "../../../../common/SagaHelper";
import {
  recipientListSlice,
  selectContactList,
  selectExcludedRecipients,
  selectFalseRecipientList,
  selectFilterList,
  selectIncludedRecipients,
  selectInitialIsSelectAll,
  selectIsChangeSelectAll,
  selectIsIncludeAndExcludeEmpty,
  selectIsSelectAll,
  selectRecipientCount,
  selectRecipientList,
  selectTrueRecipientList,
} from "./RecipientListSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import { ContactListData } from "../../BroadcastData";
import { getContactListApi, getDraftContactListApi, importRecipientsByFileApi } from "./RecipientListApi";
import { ContactListModel, ContactModel, RecipientListModel, RecipientsImportModel } from "../../BroadcastModel";
import { ContactListDataToContactListModel } from "./RecipientListMapper";
import { getTariffPlanAccessability } from "../../../../common/tariffPlan/TariffPlanUtil";
import { TariffPlanFeatureTypeEnum } from "../../../../common/AppEnums";
import { broadcastSlice } from "../../BroadcastSlice";

export function* recipientListSaga() {
  yield takeLatest(recipientListSlice.actions.getContactList, getContactList);
  yield takeLatest(recipientListSlice.actions.getDraftContactList, getDraftContactList);
  yield takeLatest(recipientListSlice.actions.importRecipientsByFile, importRecipientsByFile);
}

function* getContactList(
  action: PayloadAction<{
    botId: string;
    queryFilterParams: string;
    isOnScrollPayload?: boolean;
  }>,
) {
  try {
    yield put(beginScope("contactList2"));
    const contactList: ContactListModel | undefined = yield select(selectContactList);
    const contactListData: ContactListData = yield call(
      getContactListApi,
      action.payload.botId,
      action.payload?.queryFilterParams,
    );
    const isIncludeAndExcludeEmpty: boolean = yield select(selectIsIncludeAndExcludeEmpty);

    const contactListModel: ContactListModel = {
      items: ContactListDataToContactListModel(
        contactList?.items || {},
        contactListData.items,
        contactListData.currentPage,
        isIncludeAndExcludeEmpty,
      ),
      currentPage: contactListData.currentPage,
      totalItems: contactListData.totalItems,
      totalPages: contactListData.totalPages,
      filteredItems: undefined,
      selectedRecipientsCount: contactListData.selectedRecipientsCount,
    };
    const isSelectAll: boolean = yield select(selectIsSelectAll);
    const inititalIsSelectAll: boolean = yield select(selectInitialIsSelectAll);
    //const tariffPlans: TariffPlanFeatureModel[] = yield select(selectTariffPlan);
    const accessability: { MaxCount: number; IsUnlimited: boolean } = getTariffPlanAccessability(
      TariffPlanFeatureTypeEnum.BroadcastContacts,
      //tariffPlans,
    );
    const recipientList: RecipientListModel | undefined = yield select(selectRecipientList);
    const excludedRecipients: { [key: string]: ContactModel } | undefined = yield select(selectExcludedRecipients);
    const includedRecipients: { [key: string]: ContactModel } | undefined = yield select(selectIncludedRecipients);
    const recipientListModel: RecipientListModel = {
      ...contactListModel,
      items: ContactListDataToContactListModel(
        recipientList?.items || {},
        contactListData.items,
        contactListData.currentPage,
        isIncludeAndExcludeEmpty,
        includedRecipients,
        excludedRecipients,
        isSelectAll,
        inititalIsSelectAll,
        accessability,
      ),
    };

    const falseDefaultItems = contactListData.items.map(el => {
      return { ...el, isSelected: false };
    });

    const falseRecipientList: RecipientListModel | undefined = yield select(selectFalseRecipientList);

    const falseRecipientListModel: RecipientListModel = {
      ...contactListModel,
      items: ContactListDataToContactListModel(
        falseRecipientList?.items || {},
        falseDefaultItems,
        contactListData.currentPage,
        isIncludeAndExcludeEmpty,
      ),
    };

    const trueDefaultItems = contactListData.items.map((el, ind) => {
      return {
        ...el,
        isSelected: accessability.IsUnlimited
          ? true
          : 20 * (contactListData.currentPage - 1) + ind < accessability.MaxCount
          ? true
          : false,
      };
    });

    const trueRecipientList: RecipientListModel | undefined = yield select(selectTrueRecipientList);

    const trueRecipientListModel: RecipientListModel = {
      ...contactListModel,
      items: ContactListDataToContactListModel(
        trueRecipientList?.items || {},
        trueDefaultItems,
        contactListData.currentPage,
        isIncludeAndExcludeEmpty,
      ),
    };

    //фильтр или поиск

    const filterList: RecipientListModel | undefined = yield select(selectFilterList);
    const filterListModel: RecipientListModel = {
      ...contactListModel,
      items: ContactListDataToContactListModel(
        contactListData.currentPage > 1 ? filterList?.items || {} : {},
        contactListData.items,
        contactListData.currentPage,
        isIncludeAndExcludeEmpty,
        includedRecipients,
        excludedRecipients,
        isSelectAll,
        inititalIsSelectAll,
        undefined,
        recipientList?.items,
        undefined,
        true,
      ),
    };

    if (action.payload.queryFilterParams.includes("filter")) {
      yield put(recipientListSlice.actions.getFilterListCompleted(filterListModel));
      yield put(recipientListSlice.actions.setFalseFilterList(falseRecipientListModel));
      yield put(recipientListSlice.actions.setTrueFilterList(trueRecipientListModel));
    } else {
      yield put(recipientListSlice.actions.getFilterListCompleted(undefined));
      yield put(recipientListSlice.actions.setFalseFilterList(undefined));
      yield put(recipientListSlice.actions.setTrueFilterList(undefined));

      yield put(recipientListSlice.actions.getContactListCompleted(contactListModel));
      yield put(recipientListSlice.actions.getRecipientListCompleted(recipientListModel));
      yield put(recipientListSlice.actions.setFalseRecipientList(falseRecipientListModel));
      yield put(recipientListSlice.actions.setTrueRecipientList(trueRecipientListModel));
    }
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("contactList2"));
  }
}

function* getDraftContactList(
  action: PayloadAction<{
    botId: string;
    broadcastId: string;
    queryFilterParams: string;
  }>,
) {
  try {
    // yield put(beginScope("contactList2"));
    const contactList: ContactListModel | undefined = yield select(selectContactList);
    const contactListData: ContactListData = yield call(
      getDraftContactListApi,
      action.payload.botId,
      action.payload.broadcastId,
      action.payload?.queryFilterParams,
    );
    const isIncludeAndExcludeEmpty: boolean = yield select(selectIsIncludeAndExcludeEmpty);

    const falseDefaultItems = contactListData.items.map(el => {
      return { ...el, isSelected: false };
    });
    //const tariffPlans: TariffPlanFeatureModel[] = yield select(selectTariffPlan);
    const accessability: { MaxCount: number; IsUnlimited: boolean } = getTariffPlanAccessability(
      TariffPlanFeatureTypeEnum.BroadcastContacts,
      //tariffPlans,
    );
    const trueDefaultItems = contactListData.items.map((el, ind) => {
      return {
        ...el,
        isSelected: accessability.IsUnlimited
          ? true
          : 20 * (contactListData.currentPage - 1) + ind < accessability.MaxCount
          ? true
          : false,
      };
    });
    const contactListModel: ContactListModel = {
      items: ContactListDataToContactListModel(
        contactList?.items || {},
        contactListData.items,
        contactListData.currentPage,
        isIncludeAndExcludeEmpty,
      ),
      currentPage: contactListData.currentPage,
      totalItems: contactListData.totalItems,
      totalPages: contactListData.totalPages,
      filteredItems: undefined,
      selectedRecipientsCount: contactListData.selectedRecipientsCount,
    };
    const recipientList: RecipientListModel | undefined = yield select(selectRecipientList);
    const excludedRecipients: { [key: string]: ContactModel } | undefined = yield select(selectExcludedRecipients);
    const includedRecipients: { [key: string]: ContactModel } | undefined = yield select(selectIncludedRecipients);
    const recipientCount: number = yield select(selectRecipientCount);
    const isSelectAll: boolean = yield select(selectIsSelectAll);
    const inititalIsSelectAll: boolean = yield select(selectInitialIsSelectAll);
    const isChangeSelectAll: boolean = yield select(selectIsChangeSelectAll);
    const recipientListModel: RecipientListModel = {
      ...contactListModel,
      selectedRecipientsCount: contactListData.currentPage > 1 ? recipientCount : contactListData.selectedRecipientsCount,
      items: ContactListDataToContactListModel(
        recipientList?.items || {},
        contactListData.items,
        contactListData.currentPage,
        isIncludeAndExcludeEmpty,
        includedRecipients,
        excludedRecipients,
        isSelectAll,
        inititalIsSelectAll,
        accessability,
        undefined,
        isChangeSelectAll,
      ),
    };
    const falseRecipientList: RecipientListModel | undefined = yield select(selectFalseRecipientList);

    const falseRecipientListModel: RecipientListModel = {
      ...contactListModel,
      items: ContactListDataToContactListModel(
        falseRecipientList?.items || {},
        falseDefaultItems,
        contactListData.currentPage,
        isIncludeAndExcludeEmpty,
      ),
    };
    const trueRecipientList: RecipientListModel | undefined = yield select(selectTrueRecipientList);

    const trueRecipientListModel: RecipientListModel = {
      ...contactListModel,
      items: ContactListDataToContactListModel(
        trueRecipientList?.items || {},
        trueDefaultItems,
        contactListData.currentPage,
        isIncludeAndExcludeEmpty,
      ),
    };

    //фильтр или поиск
    const filterList: RecipientListModel | undefined = yield select(selectFilterList);
    const filterListModel: RecipientListModel = {
      ...contactListModel,
      items: ContactListDataToContactListModel(
        contactListData.currentPage > 1 ? filterList?.items || {} : {},
        contactListData.items,
        contactListData.currentPage,
        isIncludeAndExcludeEmpty,
        includedRecipients,
        excludedRecipients,
        isSelectAll,
        inititalIsSelectAll,
        undefined,
        recipientList?.items,
        undefined,
        true,
      ),
    };

    if (action.payload.queryFilterParams.includes("filter")) {
      yield put(recipientListSlice.actions.getFilterListCompleted(filterListModel));
      yield put(recipientListSlice.actions.setFalseFilterList(falseRecipientListModel));
      yield put(recipientListSlice.actions.setTrueFilterList(trueRecipientListModel));
    } else {
      yield put(recipientListSlice.actions.getFilterListCompleted(undefined));
      yield put(recipientListSlice.actions.setFalseFilterList(undefined));
      yield put(recipientListSlice.actions.setTrueFilterList(undefined));

      yield put(recipientListSlice.actions.getContactListCompleted(contactListModel));
      yield put(recipientListSlice.actions.getRecipientListCompleted(recipientListModel));
      yield put(recipientListSlice.actions.setFalseRecipientList(falseRecipientListModel));
      yield put(recipientListSlice.actions.setTrueRecipientList(trueRecipientListModel));
      if (contactListData.currentPage === 1) {
        yield put(recipientListSlice.actions.setRecipientCounter(contactListData.selectedRecipientsCount));
      }
    }
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    // yield put(completeScope("contactList2"));
  }
}

export function* importRecipientsByFile(action: PayloadAction<{ botId: string | undefined; file: File | null }>) {
  try {
    yield put(beginScope("importContacts"));
    const response: RecipientsImportModel = yield call(importRecipientsByFileApi, action.payload.botId, action.payload.file);
    yield put(recipientListSlice.actions.setImportRecipientList(response));
    yield put(broadcastSlice.actions.setInititalDisabledButtons());
  } catch (e: unknown) {
    yield put(recipientListSlice.actions.setImportRecipientList(null));
    yield handleException(e);
  } finally {
    yield put(completeScope("importContacts"));
  }
}
