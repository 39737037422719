import { call, put, takeLatest } from "redux-saga/effects";
import { beginScope, completeScope } from "../../common/loading/LoadingStateActions";
import { PayloadAction } from "@reduxjs/toolkit";
import * as telegramAuthApi from "./TelegramAuthApi";
import { handleException } from "../../common/SagaHelper";
import { telegramAuthSlice } from "./TelegramAuthSlice";
import { TelegramAuthData, TelegramAuthResponseData } from "./TelegramAuthData";
import { Locales, TelegramAuthModel } from "./TelegramAuthModel";
import { setToken } from "../auth/AuthRepo";
import { routerSlice } from "../router/RouterSlice";
import { BadRequestError, Error403Model } from "../ErrorModel";
import GTM, { setUserProperty, setUserId } from "../../common/ga/GAEventTracker";
import i18next from "i18next";
import { AppSettings } from "../AppSettings";
import { setLinkedAccountCompleted } from "../../common/user/UserSlice";
import { UserModel } from "../user/UserModel";
import { userProfileSlice } from "../../components/userProfile/UserProfileSlice";

declare const appSettings: AppSettings;

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gtag: any;
  }
}

export function* telegramAuthSaga() {
  yield takeLatest(telegramAuthSlice.actions.telegramAuthenticate, telegramAuth);
  yield takeLatest(telegramAuthSlice.actions.telegramLinkedAccountAuthenticate, telegramLinkedAccount);
}

function* telegramLinkedAccount(action: PayloadAction<TelegramAuthData>) {
  try {
    yield put(beginScope("signIn/signUp Telegram"));
    const data: TelegramAuthModel = {
      payload: action.payload.payload,
      inviteToken: null,
    };

    const resData: UserModel = yield call(telegramAuthApi.telegramLinkedAccountAuth, data);
    yield put(setLinkedAccountCompleted(resData));
    yield put(userProfileSlice.actions.getUserProfileNotifications());
  } catch (e: unknown) {
    yield handleException(e);
  } finally {
    yield put(completeScope("signIn/signUp Telegram"));
  }
}

function* telegramAuth(action: PayloadAction<{ telegramData: TelegramAuthData; redirectUrlLogin?: string }>) {
  try {
    yield put(beginScope("signIn/signUp Telegram"));
    const locale = Locales[i18next.language as keyof typeof Locales];
    const data: TelegramAuthModel = {
      inviteToken: action.payload.telegramData.inviteToken ?? null,
      locale,
      payload: action.payload.telegramData.payload,
    };
    const resData: TelegramAuthResponseData = yield call(telegramAuthApi.telegramAuth, data);
    const claims = JSON.parse(atob(resData.token.split(".")[1]));
    const availableOrganizations = JSON.parse(atob(resData.token.split(".")[1])).AvailableOrganizations;
    const organizationId = claims.Organization;
    let newOrganisationId: { [key: string]: string } = {};
    const sessionOrgId = sessionStorage.getItem("usersOrganizationId");
    try {
      const parseSessionOrg = JSON.parse(sessionOrgId || "");
      newOrganisationId = { ...parseSessionOrg };
    } catch {}

    if (sessionOrgId) {
      const orgIdsInLocalStorage = Object.values(newOrganisationId);
      const includedOrganizations = orgIdsInLocalStorage.filter(el => !availableOrganizations.includes(el));

      newOrganisationId = Object.keys(newOrganisationId)
        .filter(objKey => !includedOrganizations.includes(newOrganisationId[objKey]))
        .reduce((newObj: { [key: string]: string }, key) => {
          newObj[key] = newOrganisationId[key];
          return newObj;
        }, {});
      localStorage.setItem("usersOrganizationId", JSON.stringify(newOrganisationId));
      sessionStorage.setItem("usersOrganizationId", JSON.stringify(newOrganisationId));
    } else if (action.payload.telegramData.payload.username) {
      newOrganisationId[action.payload.telegramData.payload.username] = organizationId;
      localStorage.setItem("usersOrganizationId", JSON.stringify(newOrganisationId));
      sessionStorage.setItem("usersOrganizationId", JSON.stringify(newOrganisationId));
    }
    const token = {
      accessToken: resData?.token,
      refresh: resData?.refreshToken,
      isExpired: false,
      expiration: resData?.expiration,
    };

    const userId = claims["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"];

    setToken(token);
    trackSuccess(resData.isNew);
    setUserId(userId);
    setUserProperty({ organizationId: organizationId });

    let currentUrl = sessionStorage.getItem("currentUrl");

    const redirectUrl = () => {
      if (currentUrl === "/") {
        currentUrl = "/bots";
      }
    };
    redirectUrl();
    const reg = RegExp(/domino-crm/gm);

    const urlRegex =
      // eslint-disable-next-line no-useless-escape
      /^https:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_,\+.~#?&\/=]*)$/;
    if (
      action.payload.redirectUrlLogin &&
      reg.test(action.payload.redirectUrlLogin) &&
      urlRegex.test(action.payload.redirectUrlLogin)
    ) {
      window.location.replace(action.payload.redirectUrlLogin);
    }
    yield put(routerSlice.actions.redirect(currentUrl ? currentUrl : "/bots"));
  } catch (e: unknown) {
    if (e instanceof BadRequestError) {
      yield put(routerSlice.actions.redirect("/auth"));
    }
    if (e instanceof Error403Model) {
      trackNotApproved();
      yield put(routerSlice.actions.redirect("/auth/accountNotApproved"));
      return;
    }
    yield handleException(e);
  } finally {
    yield put(completeScope("signIn/signUp Telegram"));
  }
}

function trackSuccess(isNew: boolean) {
  if (isNew) {
    const trackEvent = GTM("Sign-up");
    trackEvent("TelegramSignupCompleted");

    if (window.gtag && appSettings.GAdsSignupConverstion) {
      window.gtag("event", "conversion", { send_to: appSettings.GAdsSignupConverstion });
    }
  } else {
    const trackEvent = GTM("Signin");
    trackEvent("TelegramSigninCompleted");
  }
}

function trackNotApproved() {
  const trackEvent = GTM("Signin");
  trackEvent("TelegramSigninNotApproved");
}
