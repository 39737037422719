import React, { useRef, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import { BroadcastMessageTypeDescriminator, FileUploadType } from "../../BroadcastModel";
import {
  selectBroadcastIsNew,
  selectBroadcastMessage,
  selectCurrentFile,
  selectCustomVariables,
  uploadBroadcastFile,
} from "../../BroadcastSlice";
import { FilterParams } from "../../../complexFilter/ComplexFilterModel";
import { BroadcastFormTitle } from "../Broadcast/components/BroadcastFormTitle/BroadcastFormTitle";
import { BroadcastFormMessage } from "../Broadcast/components/BroadcastFormMessage/BroadcastFormMessage";
import { BroadcastFormButtons } from "../Broadcast/components/BroadcastFormButtons/BroadcastFormButtons";
import { RecipientListContainer } from "../ContactList/RecipientListContainer";

interface CommonProps {
  broadcastRecipientsFilters?: FilterParams[];
  setBroadcastFilter?: (brodcastRecipientFilters: FilterParams[]) => void;
}

interface EditProps extends CommonProps {
  broadcastRecipientsFilters?: FilterParams[];
  setBroadcastFilter?: (brodcastRecipientFilters: FilterParams[]) => void;
}

type Props = EditProps;

export const BroadcastForm = (props: Props) => {
  const dispatch = useAppDispatch();
  const currentFile = useAppSelector(selectCurrentFile);
  const variables = useAppSelector(selectCustomVariables);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const isBroadcastNew = useAppSelector(selectBroadcastIsNew);
  const message = useAppSelector(selectBroadcastMessage);
  const [messageInvalid, setMessageInvalid] = useState(isBroadcastNew ? true : false);

  const uploadFile = (file: File, type: FileUploadType) => {
    dispatch(uploadBroadcastFile({ file: file, type }));
  };

  const checkIsSameFile = () => {
    if (message && currentFile && message?.typeDiscriminator !== BroadcastMessageTypeDescriminator.BroadcastTextMessage) {
      return message.fileId === currentFile.file.fileId;
    } else {
      return !currentFile;
    }
  };

  // const getActualSelectAllState = useCallback(() => {
  //   if (textareaRef.current) {
  //     textareaRef.current.value = ""; //values.message.text;
  //   }
  //   return (props as EditProps).broadcastState?.broadcast?.sentForAll;
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  // // [values.message.text];
  return (
    <>
      <BroadcastFormTitle />
      <RecipientListContainer />
      <BroadcastFormMessage
        variables={variables}
        textareaRef={textareaRef}
        messageInvalid={messageInvalid}
        setMessageInvalid={setMessageInvalid}
        uploadFile={uploadFile}
      />
      <BroadcastFormButtons
        messageInvalid={messageInvalid}
        //wasSelectAllChecked={true}
        //onSaveDraft={onSaveDraft}
        //onExecute={onExecute}
        //dirty={dirty || !checkIsSameFile()}
        dirty={!!checkIsSameFile()}
        //onBroadcastDeleteClick={(id, name) => (props as EditProps).onBroadcastDeleteClick(id, name)}
        //isValid={isValid}
        //broadcastState={broadcastState}
      />

      {/* !isSubmitting && (dirty || (isNewBroadcast ? !!currentFile : !checkIsSameFile())) */}
    </>
  );
};
