import { Box, Button, Text, Flex } from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../common/state/store";
import { selectCurrentBot } from "../../../sidebar/SidebarSlice";
import { selectBroadcastListState } from "../../BroadcastListSlice";
import { useDocumentTitle } from "../../../../common/hooks/useDocumentTitle";
import { useEffect, useState } from "react";
import { beginScope } from "../../../../common/loading/LoadingStateActions";
import { useGetLoadingState } from "../../../../common/loading/hooks/useGetLoadingState";
import { InputSearch } from "../../../../UI/molecules/inputSearch/InputSearch";
import { constructSearchParams } from "../../../../common/utils/constructSearchParams";
import useDebounce from "../../../../common/hooks/useDebounce";
import { useTabletView } from "../../../layout/LayoutHelper/ResolutionHooks";
import { useGAWithCustomParameters } from "../../../../common/ga/GAEventTracker";
import s from "./BroadcastListHeader.module.scss";
import { setTitleStatusIsNew, setTitleValidation } from "../../../broadcast/BroadcastSlice";

export const BroadcastListHeader = () => {
  const { t } = useTranslation("translation", { keyPrefix: "broadcastList" });
  useDocumentTitle(t("Broadcasting"), "Broadcasting");
  const dispatch = useAppDispatch();
  const currentBot = useAppSelector(selectCurrentBot);
  const { broadcastList } = useAppSelector(selectBroadcastListState);
  const loadingBroadcastList = useGetLoadingState("getBroadcastList");
  const navigate = useNavigate();
  const [pageParams] = useSearchParams("");
  const currentPage = Number(pageParams.get("page"));
  const [searchParams, setSearchParams] = useSearchParams();
  const search = searchParams.get("search") ?? "";
  const [searchText, setSearchText] = useState(search);
  const debouncedSearchText = useDebounce(searchText, 500);

  const isMobile = useTabletView();

  const trackEvent = useGAWithCustomParameters("Broadcast");

  useEffect(() => {
    constructSearchParams(search, debouncedSearchText, setSearchParams);
    if (search !== searchText) dispatch(beginScope("getBroadcastList"));
  }, [searchText, debouncedSearchText, search, setSearchParams, dispatch]);

  return (
    <Flex className={s.flexContainer}>
      <Box className={s.headerContainer}>
        <Box className={s.main}>
          <Text variant="h2" color="midDeepBlue">
            {t("Broadcasting")}
          </Text>
        </Box>
        {currentBot && (broadcastList?.items.length !== 0 || searchText.length !== 0 || loadingBroadcastList) && (
          <Flex className={s.buttonsContainer}>
            {!isMobile && (
              <InputSearch
                inputGroupStyles={{ mx: "24px", width: "auto" }}
                style={{ minWidth: "280px" }}
                placeholder={`${t("Search by broadcast name")}`}
                setSearchText={setSearchText}
                searchText={searchText}
              />
            )}
            <Button
              onClick={() => {
                trackEvent("BroadcastHeaderCreate");
                navigate("/broadcasts/new", { state: { page: currentPage } });
                dispatch(setTitleStatusIsNew(true));
                dispatch(setTitleValidation(false));
              }}
              size="sm"
              variant="dominoViolet"
              className={s.newBroadcastButton}
              data-pw="newBroadcastButton"
            >
              + {t("New Broadcast")}
            </Button>
          </Flex>
        )}
      </Box>
      {isMobile && (
        <Box className={s.inputSearchMobileBox}>
          <InputSearch
            inputGroupStyles={{ width: "auto" }}
            style={{ minWidth: "260px" }}
            placeholder={`${t("Search by broadcast name")}`}
            setSearchText={setSearchText}
            searchText={searchText}
          />
        </Box>
      )}
    </Flex>
  );
};
