import { Box, Flex, Grid, Heading, Input, Menu, MenuButton, MenuItem, MenuList, forwardRef } from "@chakra-ui/react";
import s from "../../ContactInfo.module.scss";
import dayjs from "dayjs";
import { CustomVariableType, BooleanVariableValues } from "../../../../common/AppEnums";
import { DatePicker, customDateInput } from "../../../../UI/atoms/datePicker/DatePicker";
import { ContactVariableModel } from "../../ContactInfoModel";
import { Dispatch, SetStateAction, useState } from "react";
import { changeContactVariable } from "../../ContactInfoSlice";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../common/state/store";

interface Props {
  setVariablesText: Dispatch<SetStateAction<Record<string, unknown> | undefined>>;
  contactId: string;
  variablesText: Record<string, unknown>;
  contactVariables: ContactVariableModel[];
}

export default function ContactInfoVariables({ setVariablesText, contactId, variablesText, contactVariables }: Props) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation("translation", { keyPrefix: "contactInfo" });
  const ct = useTranslation("translation", { keyPrefix: "commonWords" }).t;

  const [focusedInputId, setFocusedInputId] = useState("");
  const CustomInput = forwardRef(customDateInput);

  const changeVariable = (contactVariable: ContactVariableModel, defaultValue: string) => {
    if (contactVariable.value !== null) {
      if (contactId && contactVariable.value !== (defaultValue ?? "")) {
        if (contactVariable.value && contactVariable.value?.length >= 20) {
          setVariablesText(prevState => ({
            ...prevState,
            [contactVariable.id]: defaultValue ?? "",
          }));
        }
        if (contactVariable.value && !contactVariable.value.trim().length) {
          setVariablesText(prevState => ({
            ...prevState,
            [contactVariable.id]: defaultValue ?? "",
          }));
        }
        dispatch(changeContactVariable({ contactId, contactVariable }));
      }
    }

    if (contactId) {
      dispatch(changeContactVariable({ contactId, contactVariable }));
    }
  };

  const setPlaceholder = (type: CustomVariableType) => {
    if (type === CustomVariableType.Array) {
      return t("Enter array like 111,two,THREE");
    }
    return t("Enter text");
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const target = e.target as HTMLInputElement;
      target.blur();
    }
  };

  const numberValidated = (text: string) => {
    const onlyNumbersRegex = /^\d+$/;
    return onlyNumbersRegex.test(text);
  };

  const changeTextInputNumber = (e: React.ChangeEvent<HTMLInputElement>, contactVar: ContactVariableModel) => {
    if (!numberValidated(e.target.value) && e.target.value) {
      return;
    }
    setVariablesText(prevState => ({
      ...prevState,
      [contactVar.id]: e.target.value,
    }));
  };

  const changeTextInputText = (e: React.ChangeEvent<HTMLInputElement>, contactVar: ContactVariableModel) => {
    setVariablesText(prevState => ({
      ...prevState,
      [contactVar.id]: e.target.value,
    }));
  };

  const handleOnBlur = (
    e: React.FocusEvent<HTMLInputElement>,
    contactVar: ContactVariableModel,
    variablesText: Record<string, unknown>,
  ) => {
    if (!(variablesText[contactVar.id] as string).trim().length && (variablesText[contactVar.id] as string) !== "") {
      changeVariable({ ...contactVar }, contactVar.value ?? "");
      e.target.value = contactVar.value ?? "";
    } else if (!(variablesText[contactVar.id] as string).trim().length && contactVar.value == null) {
      e.target.value = "";
    } else {
      changeVariable({ ...contactVar, value: (variablesText[contactVar.id] as string).trim() }, contactVar.value ?? "");
      e.target.value = (variablesText[contactVar.id] as string).trim();
    }
    e.target.placeholder = `${ct("Not Set")}`;
    e.target.type = "text";
  };

  const getValueInput = (contactVar: ContactVariableModel) => {
    switch (contactVar?.type) {
      case CustomVariableType.DateTime: {
        return (
          <DatePicker
            data-pw="date-time-picker"
            dateFormat="dd.MM.yyyy HH:mm:ss"
            selected={(variablesText[contactVar.id] as string) ? new Date(variablesText[contactVar.id] as string) : null}
            onChange={scheduledDate => {
              setVariablesText(prevState => ({
                ...prevState,
                [contactVar.id]: dayjs(scheduledDate).isValid() ? dayjs(scheduledDate).format("YYYY-MM-DD HH:mm:ss") : null,
              }));
            }}
            placeholderText={`${ct("Not Set")}`}
            showTimeSelect
            customInput={<CustomInput className={s.input} variant={"unstyled"} placeholdertext={ct("placeholderDateTime")} />}
            strictParsing
            shouldCloseOnSelect={false}
            onCalendarClose={() =>
              changeVariable({ ...contactVar, value: variablesText[contactVar.id] as string }, contactVar.value ?? "")
            }
          />
        );
      }
      case CustomVariableType.Date: {
        return (
          <DatePicker
            data-pw="date-picker"
            dateFormat="dd.MM.yyyy"
            selected={(variablesText[contactVar.id] as string) ? new Date(variablesText[contactVar.id] as string) : null}
            onChange={scheduledDate => {
              setVariablesText(prevState => ({
                ...prevState,
                [contactVar.id]: dayjs(scheduledDate).isValid() ? dayjs(scheduledDate).format("YYYY-MM-DD") : null,
              }));
            }}
            placeholderText={`${ct("Not Set")}`}
            customInput={<CustomInput className={s.input} variant={"unstyled"} placeholdertext={ct("placeholderDate")} />}
            strictParsing
            shouldCloseOnSelect={false}
            onCalendarClose={() =>
              changeVariable({ ...contactVar, value: variablesText[contactVar.id] as string }, contactVar.value ?? "")
            }
          />
        );
      }
      case CustomVariableType.Boolean: {
        return (
          <Menu preventOverflow={false} matchWidth={true} variant="dominoDotsMenu" gutter={0}>
            <MenuButton
              color={!variablesText[contactVar.id] ? "var(--chakra-colors-mainPurple)" : "black"}
              opacity={!variablesText[contactVar.id] ? "0.5" : "1"}
              w="100%"
              bg="none"
              _hover={{ bgColor: "none" }}
              _active={{ bgColor: "none" }}
              onClick={e => e.stopPropagation()}
              data-pw="boolean-dropdown"
            >
              <Flex justifyContent={"space-between"} alignItems={"center"}>
                <Flex w={"90%"} alignItems={"center"} ml={1}>
                  {variablesText[contactVar.id] ? ct(variablesText[contactVar.id] as string) : ct("Not Set")}
                </Flex>
              </Flex>
            </MenuButton>
            <MenuList>
              {Object.keys(BooleanVariableValues).map((el, i) => {
                return (
                  <MenuItem
                    key={i}
                    value={el}
                    onClick={() => {
                      changeVariable(
                        { ...contactVar, value: el !== BooleanVariableValues["Not Set"] ? el : "" },
                        contactVar.value ?? "",
                      );
                    }}
                    data-pw="boolean-dropdown-item"
                  >
                    {ct(el)}
                  </MenuItem>
                );
              })}
            </MenuList>
          </Menu>
        );
      }
      case CustomVariableType.Number: {
        return (
          <Input
            className={s.input}
            variant={"unstyled"}
            placeholder={`${ct("Not Set")}`}
            value={variablesText[contactVar.id] as string}
            onKeyDown={e => handleKeyPress(e)}
            type={"text"}
            onChange={e => changeTextInputNumber(e, contactVar)}
            onFocus={e => {
              e.target.placeholder = setPlaceholder(contactVar.type);
              setFocusedInputId(contactVar.id);
            }}
            onBlur={e => {
              handleOnBlur(e, contactVar, variablesText);
              setFocusedInputId("");
            }}
            data-pw="number-input"
          />
        );
      }
      case CustomVariableType.Array:
      case CustomVariableType.Text: {
        return (
          <Input
            className={s.input}
            placeholder={`${ct("Not Set")}`}
            value={variablesText[contactVar.id] as string}
            onKeyDown={e => handleKeyPress(e)}
            type={contactVar.type}
            onChange={e => changeTextInputText(e, contactVar)}
            onFocus={e => {
              e.target.placeholder = setPlaceholder(contactVar.type);
              setFocusedInputId(contactVar.id);
            }}
            onBlur={e => {
              handleOnBlur(e, contactVar, variablesText);
              setFocusedInputId("");
            }}
            data-pw="text-input"
          />
        );
      }
      case CustomVariableType.Order: {
        return (
          <Input
            className={s.input}
            variant={"unstyled"}
            readOnly
            isDisabled={true}
            placeholder={`${ct("Not Set")}`}
            value={(variablesText[contactVar.id] as string) ?? `${ct("Not Set")}`}
            _hover={{ borderColor: variablesText[contactVar.id] ? "#4F3DED" : "transparent" }}
            _focus={{ borderColor: variablesText[contactVar.id] ? "#4F3DED" : "transparent" }}
          />
        );
      }
    }
  };

  return (
    <>
      {contactVariables.length ? (
        <Flex className={s.userFields} flexDirection={"column"}>
          <Flex w={"100%"}>
            <Heading fontSize={"16px"} fontWeight={"400"}>
              {t("Contact fields")}
            </Heading>
          </Flex>
          {contactVariables.map(el => {
            return (
              <Grid
                key={el.id}
                templateColumns="minmax(0, auto) auto 1fr"
                className={`${s.inputGroup} ${focusedInputId === el.id && s.inputGroupFocus}`}
                data-pw={el.key}
              >
                <Box className={s.inputLeftElement}>{el.key}</Box>
                <Box>:</Box>
                <Box data-pw="value">{getValueInput(el)}</Box>
              </Grid>
            );
          })}
        </Flex>
      ) : (
        <Flex justifyContent={"center"} className={s.noUserFields}>
          {t("No contact fields")}
        </Flex>
      )}
    </>
  );
}
